import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styles from './usuario.module.css'
import { toast } from 'react-toastify';
import { ChatBubbleLeftRightIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';


import PopupRemoverItem from '../popups/remover_item'
import PopupAddUser from '../admin/add_usuario'
import PopupHistoricoComissoes from '../popups/historico_comissoes';
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';




const TabelaUsuarios = (props) => {
  const [page, setPage] = useState(0)
  const [filtroPesquisa, setFiltroPesquisa] = useState('')
  const [filtroTipo, setFiltroTipo] = useState('')
  const [filtroAtivo, setFiltroAtivo] = useState('')
  const [filtroTrial, setFiltroTrial] = useState('')
  const [popupVisible, setPopupVisible] = useState(false)
  const [popupAddUser, setPopupAddUser] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [usuarios, setUsuarios] = useState([])
  const [lastPage, setLastPage] = useState(false)
  const [itemPopup, setItemPopup] = useState(null)
  const [popupComissoesVisible, setPopupComissoesVisible] = useState(false);

  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const exportarUsuarios = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/exportar-usuarios', {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao exportar os usuários: ", error);
    }
  };

  const getUsuarios = async () => {

    try {

      let params = {
        page
      };

      if (filtroPesquisa !== '') params.filtroPesquisa = filtroPesquisa
      if (filtroTipo !== '') params.filtroTipo = filtroTipo
      if (filtroAtivo !== '') params.filtroAtivo = filtroAtivo
      if (filtroTrial !== '') params.filtroTrial = filtroTrial;

      const users = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params
      })

      setPage(0);
      if (users.data && users.data.totalPages) setTotalPages(users.data.totalPages)
      if (users.data && users.data.usuarios) setUsuarios(users.data.usuarios/*.filter(usuario => usuario._id !== usuarioAtual._id)*/)
      if (users.data && users.data.lastPage && users.data.lastPage === true) setLastPage(true)

    } catch (err) {
    }
  }

  useEffect(() => {

    getUsuarios()

  }, [page, filtroPesquisa, filtroTipo, filtroAtivo, filtroTrial]);

  return (
    <div className={styles['tabela-aulas']}>
      {popupVisible && (
        <PopupRemoverItem buscarUsuarios={getUsuarios} fecharPopup={() => setPopupVisible(false)} itemPopup={itemPopup} tipo="Usuário" removido={() => toast.success("Usuário deletado com sucesso")} />
      )}
      {popupAddUser && (
        <PopupAddUser getUsuarios={getUsuarios} fecharPopup={() => setPopupAddUser(false)} itemPopup={itemPopup} sucesso={() => toast.success("Usuário salvo com sucesso")} erro={(err) => toast.error(err)} />
      )}
      {popupComissoesVisible && (
        <PopupHistoricoComissoes getUsuarios={getUsuarios} comissaoPaga={() => toast.success("Comissões pagas com sucesso")} adminMode="true" uid={itemPopup._id} indicacoes={itemPopup.indicacoes} totalComissoes={itemPopup.totalComissoes} fecharPopup={() => setPopupComissoesVisible(false)} />
      )}
      <div className={styles['container']}>
        <span className={styles['text']}>Usuários</span>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div
            onClick={() => {
              setItemPopup(null)
              setPopupAddUser(!popupAddUser)
            }}
            className={styles['btn-add-aula']}
          >
            <svg viewBox="0 0 804.5714285714286 1024" className={styles['icon']}>
              <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
            </svg>
            <span className={styles['text01']}>Usuário</span>
          </div>
          <div
            onClick={() => {
              exportarUsuarios()
            }}
            className={styles['btn-add-aula']}
            style={{ background: 'rgb(132 132 132)' }}
          >
            <span className={styles['text01']}>Exportar todos</span>
          </div>
        </div>
      </div>
      <div className={styles['container01']}>
        <div className={styles['container02']}>
          <span className={styles['text02']}>Procure por um usuário</span>
          <input
            type="text"
            onChange={(e) => setFiltroPesquisa(e.target.value)}
            className={styles['input-dark']}
          />
        </div>
        <div className={styles['container03']}>
          <span className={styles['text03']}>Filtre por Tipo</span>
          <select
            type="text"
            className={styles['input-dark']}
            onChange={(e) => setFiltroTipo(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="usuario">Usuário</option>
            <option value="professor">Professor</option>
            <option value="consultor">Consultor</option>
            <option value="admin">Admin</option>

          </select>
        </div>
        <div className={styles['container03']}>
          <span className={styles['text03']}>Filtre por Trial</span>
          <select
            type="text"
            className={styles['input-dark']}
            onChange={(e) => {
              setFiltroTrial(e.target.value)
            }}
          >
            <option value="">Todos</option>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
        <div className={styles['container04']}>
          <span className={styles['text04']}>Filtre por Assinantes</span>
          <select
            type="text"
            className={styles['dropdown']}
            onChange={(e) => setFiltroAtivo(e.target.value)}
          >
            <option value="">Todos</option>
            <option value={true}>Assinantes Ativos</option>
            <option value={false}>Assinantes Inativos</option>
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-neutral-800 text-white table-auto">
          <thead>
            <tr>
              <th className="text-center">Foto</th>
              <th className="text-center">Nome</th>
              <th className="text-center">Email</th>
              <th className="text-center">Tipo</th>
              <th className="text-center">Acesso</th>
              <th className="texte-center">Contactar</th>
              <th className="texte-center">Editar</th>
              <th className="texte-center text-red-500">Excluir</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.length > 0 && usuarios.map((usuario, index) => (
              <tr key={index} className="border-b border-neutral-700">
                <td className="">
                  <img src={`${usuario.foto_perfil}?timestamp=${new Date().getTime()}`} className="w-10 h-10 rounded-full" />
                </td>
                <td className="">{usuario.nome}</td>
                <td className="">{usuario.email}</td>
                <td className="">
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${usuario.tipo === 'admin' ? 'bg-blue-600' : usuario.tipo === 'usuario' ? 'bg-green-600' : usuario.tipo === 'professor' ? 'bg-pink-600' : 'bg-neutral-600'
                    }`}>
                    {usuario.tipo}
                  </span>
                </td>
                <td className="">{new Date(usuario.acesso).toLocaleDateString('pt-br')}</td>
                <td className="p-3 text-center">
                  <button className="text-neutral-400 hover:text-green-500 active:text-green-600 mr-2" title={`Falar com ${usuario.nome} no Whatsapp`} onClick={() => {
                    window.open(`https://api.whatsapp.com/send?phone=55${usuario.telefone.replaceAll(/\D/g, "")}`);
                  }}>
                    <ChatBubbleLeftRightIcon className='w-6 h-6 inline-block' />
                  </button>
                </td>
                <td className="p-3 text-center">
                  <button className="text-neutral-400 hover:text-yellow-500 active:text-yellow-600 mr-2" title={`Editar ${usuario.nome}`} onClick={() => {
                    setPopupAddUser(true)
                    setItemPopup(usuario)
                  }}>
                    <PencilSquareIcon className='w-6 h-6 inline-block' />
                  </button>
                </td>
                <td className="p-3 text-center">
                  <button className="text-neutral-400 hover:text-red-500 active:text-red-600" title={`Excluir ${usuario.nome}`} onClick={() => {
                    setPopupVisible(true)
                    setItemPopup(usuario)
                  }}>
                    <TrashIcon className='w-6 h-6 inline-block' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {totalPages > 1 && (
          <div className="mt-4 flex justify-center">
            {page > 0 && (
              <button
                className="px-4 py-2 text-neutral-400 hover:text-white"
                onClick={() => setPage(page - 1)}
              >
                <ChevronLeftIcon className='w-5 h-5' />
              </button>
            )}
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i}
                className={`ml-2 px-4 py-2 ${page === i ? 'text-white' : 'text-neutral-400 hover:text-white'}`}
                onClick={() => setPage(i)}
              >
                {i + 1}
              </button>
            ))}
            {page < totalPages - 1 && (
              <button
                className="ml-2 px-4 py-2 text-neutral-400 hover:text-white"
                onClick={() => setPage(page + 1)}
              >
                <ChevronRightIcon className='w-5 h-5' />
              </button>
            )}
          </div>
        )}

      </div>


    </div>
  )
}

export default TabelaUsuarios