import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

const FornecedoresEParceiros = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [sendContact, setSendContact] = useState(false);

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Seja um Parceiro Homologado | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const [diferencaTempo, setDiferencaTempo] = useState(0);

    useEffect(() => {
        mixPanelAcesso();

        const interval = setInterval(() => {
            setDiferencaTempo(prevTempo => prevTempo + 1);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const mixPanelContato = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track("Seja um Parceiro Homologado | Contato", {
            distinct_id: usuarioAtual._id,
            tempo_convencimento: diferencaTempo,
        });
    }

    const sendEmail = () => {
        setSendContact(true);
        console.log(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`);

        axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`,
            {
                recipientName: usuarioAtual.name,
                recipientEmail: usuarioAtual.email,
                subject: 'QUERO SER UM FORNECEDOR/PARCEIRO',
                htmlContent: `Nome: ${usuarioAtual.nome}<br>Email: ${usuarioAtual.email}<br>Telefone: ${usuarioAtual.telefone || "Não possui"}`
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then(response => {
                mixPanelContato();
                console.log("RESPOSTA DO SERVIDOR: ", response);
                toast.success('E-mail enviado com sucesso!');
            })
            .catch(error => {
                console.log("RESPOSTA DO SERVIDOR: ", error);
                toast.error('Erro ao enviar e-mail. Por favor, tente novamente mais tarde');
                setSendContact(false);
            });
    }

    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row md:p-5 gap-10'>
                    {showMenu &&
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    }
                    <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                        <div className="flex flex-col w-full bg-orange-700 rounded-lg text-white">
                            {/* Hero Section */}
                            <div className="text-center py-10 text-neutral-50 w-10/12 mx-auto">
                                <h1 className="text-xl md:text-3xl lg:text-5xl font-extrabold leading-tight mb-4 text-white">
                                    Seja um Parceiro Homologado e Aumente sua Exposição
                                </h1>
                                <p className="text-xs md:text-sm lg:text-lg mt-2">
                                    Faça parte do Ecossistema Simbium: Seja um parceiro homologado e aumente sua exposição.
                                </p>
                            </div>

                            {/* Services Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold">
                                    Nossa plataforma é um espaço onde empresas podem se tornar parceiros homologados:
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 xl:gap-20 select-none">
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M480-600 340-740l140-140 140 140-140 140ZM40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm120-280q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440Z" /></svg>
                                        <p className="text-lg">Trading</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z" /></svg>
                                        <p className="text-lg">Fornecedores de produtos</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="m352-522 86-87-56-57-44 44-56-56 43-44-45-45-87 87 159 158Zm328 329 87-87-45-45-44 43-56-56 43-44-57-56-86 86 158 159Zm24-567 57 57-57-57ZM290-120H120v-170l175-175L80-680l200-200 216 216 151-152q12-12 27-18t31-6q16 0 31 6t27 18l53 54q12 12 18 27t6 31q0 16-6 30.5T816-647L665-495l215 215L680-80 465-295 290-120Zm-90-80h56l392-391-57-57-391 392v56Zm420-419-29-29 57 57-28-28Z" /></svg>
                                        <p className="text-lg">Fornecedores de serviços (SaaS)</p>
                                    </div>
                                </div>
                            </div>

                            {/* Benefits Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-800 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Benefícios de ser um parceiro homologado:
                                </h2>
                                <ul className="flex flex-col gap-5 text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Grande exposição para sua empresa:</strong> Aumente a visibilidade da sua empresa no mercado.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Aumento de vendas:</strong> Amplie suas oportunidades de negócios.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Atração de novos clientes:</strong> Conquiste novos mercados e clientes.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Fortalecimento de sua marca:</strong> Reforce a presença e credibilidade da sua marca.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Acesso a ferramentas e recursos exclusivos:</strong> Utilize nossas ferramentas para melhorar seus processos.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Suporte personalizado de nossa equipe:</strong> Receba suporte especializado para ajudar no seu sucesso.</p>
                                    </li>
                                </ul>
                            </div>

                            {/* Requisitos Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-950 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Requisitos para se tornar um parceiro homologado:
                                </h2>
                                <ul className="flex flex-col gap-5 text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="gap-5 pb-5 text-center md:text-left">
                                        <strong className=''>1. Ser uma empresa legalmente constituída:</strong> Sua empresa deve ser registrada e operar legalmente.
                                    </li>
                                    <li className="gap-5 pt-10 pb-5 text-center md:text-left">
                                        <strong className=''>2. Ter um histórico financeiro sólido:</strong> Demonstrar estabilidade financeira.
                                    </li>
                                    <li className="gap-5 pt-10 pb-5 text-center md:text-left">
                                        <strong className=''>3. Ter uma boa reputação no mercado:</strong> Ser reconhecida por sua integridade e qualidade.
                                    </li>
                                    <li className="gap-5 pt-10 pb-5 text-center md:text-left">
                                        <strong className=''>4. Oferecer produtos ou serviços de alta qualidade:</strong> Produtos e serviços devem atender a altos padrões.
                                    </li>
                                    <li className="gap-5 pt-10 text-center md:text-left">
                                        <strong className=''>5. Estar comprometida com o atendimento ao cliente:</strong> Priorizar a satisfação do cliente.
                                    </li>
                                </ul>
                            </div>

                            {/* Call to Action Section */}
                            <div className="text-center p-5 lg:p-20 py-10 lg:py-20 shadow-md bg-orange-700 rounded-b-md">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-neutral-50 mb-10">
                                    Contate-nos agora e torne-se um parceiro homologado!
                                </h2>
                                {!sendContact ?
                                    <button className='p-5 w-full bg-green-500 hover:animate-none hover:bg-green-400 active:bg-green-600 animate-pulse text-orange-50 text-lg md:text-xl lg:text-2xl rounded-lg' onClick={() => sendEmail()}>Entrar em contato por e-mail</button>
                                    :
                                    <p className='text-orange-100'>Contato enviado. Entraremos em contato assim que possível {";)"}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default FornecedoresEParceiros