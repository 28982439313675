import React, { useState, useEffect } from 'react';
import { ChevronUpIcon, ChevronDownIcon, CheckBadgeIcon, PencilIcon, XMarkIcon, HomeIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setUsuario } from '../../../features/usuario/usuarioAtual';
import { Provider, useSelector, useDispatch, useStore } from 'react-redux';
import { PlusIcon } from '@heroicons/react/24/solid';

const TrilhaAdmin = () => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const [opcao1, setOpcao1] = useState('sou-loja-fisica');
    const [opcao2, setOpcao2] = useState('');

    const [trilha, setTrilha] = useState({
        titulo: "",
        slug: "",
        descricao: "",
        opcoes: [],
        acoes: [],
    });

    const [acao, setAcao] = useState({
        acao: "",
        tipo: "",
        dado: null,
    });

    useEffect(() => {
        const fetchTrilha = () => {

        }
        fetchTrilha();
    }, [trilha])

    return (
        <div className="gap-5 w-full flex p-8 relative flex-col pb-8 justify-start bg-neutral-800 rounded-xl">
            <div className="w-full mx-auto">
                <h1 className="text-white text-2xl">Trilha</h1>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 divide-y divide-neutral-500 md:divide-y-0 md:divide-x'>
                    <div className='p-5'>
                        <label htmlFor="op1" className="flex items-center text-white my-5">
                            <input
                                id="op1"
                                type="checkbox"
                                //checked={op1}
                                // onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Sou loja física
                        </label>
                        <label htmlFor="op2" className="flex items-center text-white my-5">
                            <input
                                id="op2"
                                type="checkbox"
                                //checked={op2}
                                //onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Já vendo online
                        </label>
                        <label htmlFor="op3" className="flex items-center text-white my-5">
                            <input
                                id="op3"
                                type="checkbox"
                                //checked={op3}
                                //onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Estou começando do zero
                        </label>
                    </div>

                    <div className='p-5'>
                        <label htmlFor="op4" className="flex items-center text-white my-5">
                            <input
                                id="op4"
                                type="checkbox"
                                //checked={op4}
                                // onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Estou começando do absoluto zero
                        </label>
                        <label htmlFor="op5" className="flex items-center text-white my-5">
                            <input
                                id="op5"
                                type="checkbox"
                                //checked={op5}
                                //onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Entre 10K e 20K por mês em vendas
                        </label>
                        <label htmlFor="op6" className="flex items-center text-white my-5">
                            <input
                                id="op6"
                                type="checkbox"
                                //checked={op6}
                                //onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Mais de 20K por mês em vendas, querendo escalar
                        </label>
                        <label htmlFor="op7" className="flex items-center text-white my-5">
                            <input
                                id="op7"
                                type="checkbox"
                                //checked={op7}
                                //onChange={atualizarMarketplace}
                                className="mr-2 rounded disabled:opacity-50" //disabled={processando}
                            />
                            Lançar E-Commerce do Zero (já vendo online)
                        </label>
                    </div>
                </div>
                <h2 className='text-neutral-500 text-xl my-5'>Monte a trilha da combinação acima:</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
                    <div className=''>
                        <ul className='flex flex-col gap-5 text-lg text-white'>
                            <li className="flex items-center align-middle gap-3">
                                <button
                                    className="hover:scale-125"
                                    onClick={() => setTrilha((prev) => ({
                                        ...prev,
                                        acoes: [...(prev.acoes || []), 'agendar-consultoria'] // Verifica se prev.acoes existe, se não, usa um array vazio.
                                    }))}
                                >
                                    <PlusIcon className="w-5 h-5" />
                                </button>
                                Agendar uma consultoria
                            </li>

                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Ver vídeo de boas vindas até o final
                            </li>
                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Assitir um vídeo do shorts simbium
                            </li>
                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Acessar a Comunidade
                            </li>
                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Acessar um Produto Catalogado
                            </li>
                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Acessar um Marketplace
                            </li>
                            <li className='flex items-center align-middle gap-3'>
                                <button className='hover:scale-125'>
                                    <PlusIcon className='w-5 h-5' />
                                </button>
                                Agendou uma consultoria
                            </li>

                        </ul>
                    </div>
                    <div className=''></div>
                </div>
            </div>
        </div>
    );
};

export default TrilhaAdmin;
