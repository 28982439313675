import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GridLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './store';

import './style.css';
// import './react-calendar.css';

// Importação das views e componentes comentadas
// import DiagnosticoForm from './views/diagnostico-form';
// import Diagnostico from './views/diagnostico';
// import Curso from './views/curso';
// import Aula from './views/aula';
// import MeusCursos from './views/meus-cursos';
// import HomeClean from './views/home-clean';
// import Indicacao from './views/indicacao';
// import Onboarding from './components/onboarding';
// import PopupBoasVindas from './components/popups/boas_vindas';
// import EmBreve from './views/em-breve';
// import AulaNovoPadrao from './views/aula-novo-padrao';
// import IA from './views/ia';

import { configureStore } from '@reduxjs/toolkit';

// Importação das views e componentes descomentadas
import MeuPerfil from './views/meu-perfil';
import TermosDeUso from './views/termos-de-uso';
import MeusColaboradores from './views/meus-colaboradores';
import Admin from './views/admin';
import Consultor from './views/consultor';
import PolticasDePrivacidade from './views/polticas-de-privacidade';
import Login from './views/login';
import HomeOfficial from './views/home-official/main';
import TrialExpirado from './views/trial-expirado';
import Shorts from './views/shorts';
import Master from './views/master';
import MasterAula from './views/master-aula';
import LandingPageAutomacao from './views/landing-page-automacao';

import Consultorias from './views/consultorias/main';
import FranquiaSimbium from './views/franquia-simbium/main';
import ImportacaoCompartilhada from './views/importacao-compartilhada/main';
import FornecedoresEParceiros from './views/fornecedores-e-parceiros/main';
import ProdutosCatalogados from './views/produtos-catalogados/main';
import Marketplace from './views/marketplace/main';
import SejaConsultor from './views/seja-consultor/main';
import EmprestimoSimbium from './views/emprestimo-simbium/main';

import { setUsuario } from './features/usuario/usuarioAtual';

import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });

const App = () => (
  <Provider store={store}>
    <Content />
  </Provider>
);

const Content = () => {
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  //const usuarioAtual = usuarioAtual; //useSelector(state => state.usuarioAtual);
  const [isLoading, setIsLoading] = useState(true);
  const [popupBoasVindasVisible, setPopupBoasVindasVisible] = useState(true);
  const [onboardingOpened, setOnboardingOpened] = useState(false);
  const [videoFrame, setVideoFrame] = useState(null);



  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setIsLoading(false);
        console.error("Token não encontrado.");
        return;
      }

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuario_atual`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (data) {
          dispatch(setUsuario(data)); // Atualiza o estado global com os dados do usuário
        } else {
          console.error("Erro ao carregar dados do usuário:", data);
        }
      } catch (err) {
        console.error("Erro ao carregar dados do usuário:", err.response?.data || err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();

    /*const handleRouteChange = () => window.scrollTo(0, 0);
    window.addEventListener('hashchange', handleRouteChange);

    return () => {
     window.removeEventListener('hashchange', handleRouteChange);
    };*/
  }, [dispatch]);

  useEffect(() => {
    if (!mixpanel) return;
    if (usuarioAtual?._id && usuarioAtual.tipo !== 'guest') {
      mixpanel.identify(usuarioAtual._id);
      mixpanel.people.set({
        $name: usuarioAtual.nome,
        $email: usuarioAtual.email,
        $plan: usuarioAtual.ref.plano
      });
    }
  }, [usuarioAtual, mixpanel])

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => ['usuario', 'admin', 'consultor'].includes(usuarioAtual?.tipo) ? <Component {...props} /> : <Redirect to="/login" />} />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => ['admin'].includes(usuarioAtual?.tipo) ? <Component {...props} /> : <Redirect to="/" />} />
  );

  const ConsultorRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => ['admin', 'consultor'].includes(usuarioAtual?.tipo) ? <Component {...props} /> : <Redirect to="/" />} />
  );


  return (
    <Router>
      <div>
        {/* usuarioAtual?.onboarding && !usuarioAtual.onboarding.concluido && (
          <Onboarding opened={onboardingOpened} />
        )*/}
        {/* false && usuarioAtual?.onboarding && !usuarioAtual?.onboarding?.etapas.includes('video-boas-vindas') && popupBoasVindasVisible && (new Date(usuarioAtual.acesso) >= new Date()) && (
          <PopupBoasVindas
            videoFrame={videoFrame}
            usuarioAtual={usuarioAtual}
            abrirOnboarding={() => setOnboardingOpened(true)}
            fecharPopup={() => setPopupBoasVindasVisible(false)}
          />
        )*/}
        <ToastContainer className="toast" theme="dark" />
        {isLoading ? (
          <div className="loading-overlay">
            <GridLoader color="#ff5b1c" size={18} />
          </div>
        ) : (
          <Switch>
            <PrivateRoute component={MeuPerfil} exact path="/meu-perfil" />
            <Route component={TermosDeUso} exact path="/termos-de-uso" />
            <PrivateRoute component={MeusColaboradores} exact path="/meus-colaboradores" />
            {/*<PrivateRoute component={Diagnostico} exact path="/diagnostico" />*/}
            {/*<PrivateRoute component={DiagnosticoForm} exact path="/diagnostico-form" />*/}
            {/*<PrivateRoute component={Curso} path="/curso/:slug" />*/}
            <AdminRoute component={Admin} path="/admin" />
            <ConsultorRoute component={Consultor} path="/consultor" />
            <Route component={PolticasDePrivacidade} exact path="/politicas-de-privacidade" />
            {/*<PrivateRoute component={Aula} path="/aula/:slug" />*/}
            {/*<PrivateRoute component={MeusCursos} exact path="/meus-cursos" />*/}
            <PrivateRoute component={Master} exact path="/master/:pagina_codigo" />
            <PrivateRoute component={MasterAula} exact path="/master/aula/:pagina_codigo/:curso_codigo/:aula_codigo?" />
            {/*<PrivateRoute component={AulaNovoPadrao} exact path="/aula-novo-padrao" />*/}
            <PrivateRoute component={Shorts} exact path="/shorts" />
            {/*<PrivateRoute component={IA} exact path="/ia" />*/}

            <PrivateRoute component={Consultorias} exact path="/consultorias" />
            <PrivateRoute component={FranquiaSimbium} exact path="/franquia-simbium" />
            <PrivateRoute component={ImportacaoCompartilhada} exact path="/importacao-compartilhada" />
            <PrivateRoute component={FornecedoresEParceiros} exact path="/fornecedores-e-parceiros" />
            <PrivateRoute component={ProdutosCatalogados} exact path="/produtos-catalogados" />
            <PrivateRoute component={Marketplace} exact path="/marketplace" />
            <PrivateRoute component={SejaConsultor} exact path="/seja-consultor" />
            <PrivateRoute component={EmprestimoSimbium} exact path="/emprestimo-simbium" />

            {/*<PrivateRoute component={EmBreve} exact path="/em-breve" />*/}
            <PrivateRoute component={LandingPageAutomacao} exact path="/automacao/:pagina_codigo/:curso_codigo" />
            {/*<PrivateRoute component={HomeClean} exact path="/home-clean" />*/}
            <Route component={HomeOfficial} exact path="/" />
            <PrivateRoute component={HomeOfficial} exact path="/em-breve/:pagina_codigo" />
            {/*<Route path="/indicacao/:id" component={Indicacao} />*/}
            <Route path="/assine-simbium" component={TrialExpirado} />
            <Route component={Login} exact path="/login" />
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));

export default App;
