// components/PageCard.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const PageCard = ({ index, paginasLength, pagina, secoesPagina, trocarSecaoPagina, processando, deletarPagina, alterarOrdemPagina }) => {
    const history = useHistory();

    return (
        <div className="flex flex-col justify-between group relative p-5 bg-gray-700 bg-cover bg-center bg-no-repeat rounded-lg overflow-hidden transform transition-transform duration-100 ease-in-out scale-100 hover:scale-105 text-white cursor-pointer"
            style={{ backgroundImage: `url(${pagina.bannerImagem})` }}
            onClick={() => {
                localStorage.setItem('master-pagina', pagina.codigo);
                history.push(`master-pagina/${pagina._id}`);
            }}>
            <div>
                <div className="mb-5 relative z-10 text-xl">{pagina.titulo}</div>
                <div className="relative z-10 text-sm text-gray-300">{pagina.descricao}</div>
            </div>
            <div className="relative z-10 text-sm text-gray-300">
                <select
                    className="block w-full mt-3 bg-neutral-700 text-neutral-100 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6 disabled:opacity-50"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onChange={(e) => trocarSecaoPagina(pagina._id, e.target.value)}
                    value={pagina.secaoPagina || ""}
                    disabled={processando}
                >
                    <option value={""}>Selecione uma seção</option>
                    {secoesPagina.map((secao) => (
                        <option key={secao._id} value={secao._id}>{secao.titulo}</option>
                    ))}
                </select>
                <div className='flex justify-between mt-2'>
                    <button className='w-full text-center text-xs text-neutral-400 hover:text-red-500 active:text-red-600 disabled:opacity-50' disabled={processando} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deletarPagina(pagina._id, pagina.titulo)
                    }} >Excluir Disciplina</button>
                    <div className='flex gap-1'>
                        {index > 0 &&
                            <button className='w-full text-xs text-white hover:scale-125 text-left disabled:opacity-50' disabled={processando} title='Mover para trás' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                alterarOrdemPagina(pagina._id, -1)
                            }} >

                                <ChevronLeftIcon className='w-4 h-4' />
                            </button>
                        }
                        {index < (paginasLength - 1) &&
                            <button className='w-full text-xs text-white hover:scale-125 text-right disabled:opacity-50' disabled={processando} title='Mover para trás' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                alterarOrdemPagina(pagina._id, 1)
                            }} >

                                <ChevronRightIcon className='w-4 h-4' />
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className="absolute inset-0 bg-black opacity-70 z-0 group-hover:opacity-50"></div>
        </div>
    );
};

export default PageCard;
