import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Menu from '../../components/menu';

import ModalAgendamentos from '../../views/home-official/consultoria/modal-agendamentos';
import { CalendarDaysIcon, PencilIcon } from '@heroicons/react/24/outline';

import DiagnosticForm from './diagnostic-form/main';
import Trilha from './trilha/main';
import ShortsHome from './shorts/main';
import ColaboradoresHome from './colaboradores/main';
import ConsultoriaHome from './consultoria/main';
import ConteudosHome from './conteudos/main';
import AutomacoesHome from './automacoes/main';
import PopupAgendamento from '../../components/popups/add_consultoria';

const HomeOfficial = (props) => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

    const [agendamentoPopupVisible, setAgendamentoPopupVisible] = useState(false);

    const checkUser = (event) => {
        event.stopPropagation();

        if (usuarioAtual.tipo === 'guest') {
            window.location.replace('/login');
        }
    }

    return (
        <div className='w-full' onClick={(event) => checkUser(event)}>
            {agendamentoPopupVisible && (
                <PopupAgendamento usuarioAtual={usuarioAtual} erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} fecharPopup={() => setAgendamentoPopupVisible(false)} />
            )}
            <div className='block'>
                <Helmet>
                    <title>Simbium</title>
                    <meta property="og:title" content="Simbium" />
                </Helmet>
                <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                <div className='bg-neutral-900 flex flex-col font-poppins'>
                    <div className='w-full flex flex-row md:p-5 mt-5 md:mt-0 gap-10'>
                        {showMenu &&
                            <>
                                <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                                <div className='hidden w-0 lg:block lg:w-3/12'></div>
                            </>
                        }
                        <div className={`${showMenu ? 'lg:w-9/12' : 'lg:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>

                            {false && usuarioAtual.tipo !== 'guest' && <DiagnosticForm />}

                            <Trilha />

                            <section className='flex sm:hidden w-full hover:shadow-orange-500'>
                                <div className='w-11/12 mx-auto relative flex flex-row rounded-xl bg-gradient-to-r from-[#fe631c] to-[#fe941e]' onClick={() => setAgendamentoPopupVisible(true)}>
                                    <div className='w-1/2 m-5'>
                                        <p className='text-xl mb-2 font-bold'>Agende sua consultoria!</p>
                                        <p className='text-xs'>Converse agora com seu supervisor</p>
                                    </div>
                                    <img src="/home-official/agende-consultoria-mood.png" className='w-[150px] absolute bottom-0 right-3' />
                                </div>
                            </section>

                            <AutomacoesHome usuarioAtual={usuarioAtual} />

                            <ConsultoriaHome usuarioAtual={usuarioAtual} erro={(e) => { toast.error(e) }} sucesso={() => toast.success("Consultoria agendada com sucesso!")} />
                            {/*<ColaboradoresHome usuarioAtual={usuarioAtual} />*/}


                            <ShortsHome usuarioAtual={usuarioAtual} />

                            {/*<TreinamentosHome />*/}

                            <section className='flex sm:hidden w-full hover:shadow-blue-500 py-10'>
                                <a target="_blank" href={`${process.env.REACT_APP_COMUNIDADE_URL}`} className='w-11/12 mx-auto relative flex flex-row justify-end rounded-xl bg-gradient-to-r from-[#041628] to-blue-900'>
                                    <img src="/home-official/acesse-comunidade.png" className='w-[250px] absolute bottom-0 -left-10' />
                                    <div className='w-1/2 m-5 text-white text-right'>
                                        <p className='text-xl mb-2 font-bold'>Acesse a comunidade!</p>
                                        <p className='text-xs text-blue-200'>Junte-se a nós</p>
                                    </div>
                                </a>
                            </section>

                            <ConteudosHome usuarioAtual={usuarioAtual} />
                            {/*<IndiqueGanheHome usuarioAtual={usuarioAtual} />*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className='block lg:hidden'>
                {showMenu &&
                    <div className='w-full'>
                        <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                    </div>
                }
                <HomeMobile usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>*/}
            <Footer></Footer>
        </div>
    )
}

export default HomeOfficial;
