// features/usuario/usuarioAtual.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id: null,
  nome: 'Usuário Padrão',
  email: 'usuariopadrao@email.com',
  trial: true,
  ip: '', // Campo opcional
  telefone: '', // Campo opcional
  foto_perfil: '', // Campo opcional
  senha: '', // A senha normalmente não deve ser armazenada no estado por questões de segurança
  area: '', // Campo opcional
  consultorias: {
    cota: 0,
    ultimoMesCotaAtualizada: null,
    restricao_semanal: true,
    adesao: true,
    expiracao: (new Date(Date.now() + (365 * 24 * 60 * 60 * 1000) + (3 * 60 * 60 * 1000))).toISOString(), // 1 ano mais 3 horas
  },
  diagnostico: [],
  onboarding: {
    etapas: [], // As etapas só serão preenchidas durante o onboarding
    concluido: false,
  },
  ref: {
    uid: null,
    pago: false,
    plano: '',
  },
  equipe_admin: null,
  creditos: 0,
  tipo: 'guest', // Tipo padrão
  consumidorId: null,
  horariosDisponiveis: [], // Disponibilidades não definidas inicialmente
  acesso: (new Date(Date.now() + (365 * 24 * 60 * 60 * 1000) + (3 * 60 * 60 * 1000))).toISOString(), // 1 ano mais 3 horas
  data_criacao: (new Date()).toISOString(),
  resetPasswordToken: null,
  resetPasswordExpiry: null,
};

const usuarioAtualSlice = createSlice({
  name: 'usuarioAtual',
  initialState,
  reducers: {
    // Atualiza o estado do usuário com o payload da action
    setUsuario: (state, action) => {
      return action.payload; // Atualiza apenas os campos passados no payload
    },
    // Exemplo de outro reducer para resetar o estado do usuário para o padrão
    resetUsuario: () => initialState,
  },
});

export const { setUsuario, resetUsuario } = usuarioAtualSlice.actions;
export default usuarioAtualSlice.reducer;
