import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import Header from '../components/header';
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside';
import PopupAgendamento from '../components/popups/add_consultoria';
import PopupShorts from '../components/popups/shorts';
import axios from 'axios';
import './shorts.css';
import { useSelector, useDispatch } from 'react-redux';


const Shorts = () => {
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [filtroPesquisa, setFiltroPesquisa] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [popupShortsVisible, setPopupShortsVisible] = useState([]);
  const [tags, setTags] = useState([
  ]);
  const [shorts, setShorts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  const shortsBoxRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleTagChange = (tagName, event) => {
    event.stopPropagation();

    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.nome === tagName ? { ...tag, checked: !tag.checked } : tag
      )
    );
  };

  const handleDropdownVisibility = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleClickShort = async (short) => {
    const currentIndex = shorts.findIndex((s) => s._id === short._id);

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
    let nextIndex = currentIndex < shorts.length - 1 ? currentIndex + 1 : null;

    const popupShorts = [];

    if (prevIndex !== null) {
      popupShorts.push(shorts[prevIndex]);
    } else {
      popupShorts.push('')
    }

    popupShorts.push(short);

    if (nextIndex !== null) {
      popupShorts.push(shorts[nextIndex]);
    } else {
      popupShorts.push('')
    }

    setPopupShortsVisible(popupShorts);
  };

  const countSelectedTags = () => {
    return tags.filter(tag => tag.checked).length
  };

  const isNovo = (dataCriacao) => {
    const dataAtual = new Date();
    const dataCriacaoBrasilia = new Date(dataCriacao);
    dataCriacaoBrasilia.setHours(dataCriacaoBrasilia.getHours() - 3);

    return dataCriacaoBrasilia > dataAtual.setHours(0, 0, 0, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {

    if (popupShortsVisible.length > 0) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "initial";
    }

  }, [popupShortsVisible])

  const fetchData = async (pagina) => {
    if (loading) return;

    setLoading(true)
    const filteredTags = tags
      .filter(tfil => tfil.checked)
      .map(tfil => tfil.nome);

    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        params: {
          skip: pagina > 0 ? (pagina) * 20 : 0,
          limit: 20,
          shorts: true,
          busca: filtroPesquisa !== '' ? filtroPesquisa : null,
          tags: filteredTags && filteredTags.length > 0 ? filteredTags.join("$@") : null
        }
      });

      if (!response.data || !response.data.aulas || response.data.aulas.length === 0) {
        setShouldLoadMore(false);
      } else {
        if (pagina === 0) {
          setShorts(response.data.aulas);
        } else {
          const newShorts = response.data?.aulas.filter(item => {
            return shorts.length === 0 || !shorts.some(f => f._id === item._id)
          });
          setShorts([...shorts, ...newShorts]);
        }
        setCurrentPage(pagina);
        if (response.data.aulas.length === 20) {
          setShouldLoadMore(true)
        } else {
          setShouldLoadMore(false)
        }
      }

    } catch (error) {
      setShouldLoadMore(false)
    }

    setLoading(false)
  };
  useEffect(() => {
    fetchData(currentPage);
  }, []);

  useEffect(() => {
    if (filtroPesquisa !== '' || tags.length > 0) {
      fetchData(0);
    }
  }, [filtroPesquisa, tags])

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  useEffect(() => {
    const fetchUniqueTags = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas/tags-shorts', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.data && response.data.length > 0) {
          const newTags = response.data.map(nt => {
            return {
              nome: nt,
              checked: false
            }
          })
          setTags(newTags);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUniqueTags();
  }, []);

  return (
    <div className='w-full'>
      {popupShortsVisible && popupShortsVisible.length > 0 && (
        <PopupShorts
          video={popupShortsVisible}
          handleClickShort={(e) => {
            handleClickShort(e)
          }}
          fecharPopup={() => setPopupShortsVisible([])}
        />
      )}
      <Helmet>
        <title>Simbium</title>
        <meta property="og:title" content="Simbium" />
      </Helmet>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className='bg-neutral-900 flex flex-col font-poppins'>
        <div className='w-full flex flex-row md:p-5 gap-10'>
          {showMenu &&
            <>
              <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
              <div className='hidden w-0 lg:block lg:w-3/12'></div>
            </>
          }
          <div className={`${showMenu ? 'w-full lg:w-9/12' : 'lg:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
            <div className="flex flex-col gap-8 w-full p-7 bg-[#212121] rounded-lg" ref={shortsBoxRef}>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <svg viewBox="0 0 1024 1024" className="h-6 w-auto fill-white">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                  <span className="text-white text-2xl font-medium">Shorts</span>
                </div>
                <span className="text-gray-400 leading-snug max-w-2xl">Aulas direto ao ponto em vídeos de até 90 segundos para te ajudar a destravar assuntos específicos!</span>
              </div>
              <div className="filtros">
                <div className="filtro">
                  <span className="label">Procure por um termo</span>
                  <input
                    type="text"
                    value={filtroPesquisa}
                    onChange={(e) => setFiltroPesquisa(e.target.value)}
                    className="input-dark"
                  />
                </div>
                {tags.length > 0 && (
                  <div className="filtro">
                    <span className="label">Filtre por tags</span>
                    <div className="dropdown-container" ref={dropdownRef}>
                      <div className="dropdown-header" onClick={handleDropdownVisibility} style={isDropdownVisible ? { borderRadius: '10px 10px 0 0' } : {}}>
                        {countSelectedTags() === 0 ? 'Selecione uma tag' : `${countSelectedTags()} tag${countSelectedTags() > 1 ? 's' : ''} selecionada${countSelectedTags() > 1 ? 's' : ''}`}
                      </div>
                      {isDropdownVisible && (
                        <div className="dropdown-content">
                          {tags && tags.length > 0 && tags.map((tag, i) => (
                            <label key={i} onClick={(e) => e.stopPropagation()}>
                              <input
                                type="checkbox"
                                checked={tag.checked}
                                onChange={(e) => handleTagChange(tag.nome, e)}
                              />
                              {tag.nome}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {shorts.filter(sh => isNovo(sh.data_criacao)).length > 0 && (
                <div className="info">
                  <div></div>
                  <p>{shorts.filter(sh => isNovo(sh.data_criacao)).length} novos shorts <strong>adicionados hoje</strong></p>
                </div>
              )}
              <div className="shorts">
                {shorts && shorts.length > 0 && shorts.map((short) => (
                  <div className="short border-2 border-transparent hover:border-orange-500 rounded-xl cursor-pointer overflow-hidden" key={short._id} onClick={() => handleClickShort(short)}>
                    <img src={short.capa} />
                    <div className="tags">
                      {short.tags && short.tags.filter((tf, idx) => (idx < 2)).map((t, i) => (
                        <div key={i} className="tag">{t}</div>
                      ))}
                    </div>
                    {isNovo(short.data_criacao) && <div className="tag-novo">Novo</div>}
                  </div>
                ))}
              </div>
              {shouldLoadMore && (
                <button className="btn-vermais" onClick={() => fetchData(currentPage + 1)} disabled={loading}>{loading ? 'Carregando...' : 'Ver mais'}</button>
              )}
            </div>
          </div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Shorts;
