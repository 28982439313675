import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import axios from 'axios'
// import styles from './master.module.css'
import './master.css'


const MasterPaginaAdmin = (props) => {
  const history = useHistory();
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }

  const { pagina_id } = useParams();
  const [pagina, setPagina] = useState(null);
  const [secoes, setSecoes] = useState([]);

  const [tituloPagina, setTituloPagina] = useState("");
  const [erroTituloPagina, setErroTituloPagina] = useState("");

  const [descricaoPagina, setDescricaoPagina] = useState("");
  const [erroDescricaoPagina, setErroDescricaoPagina] = useState("");

  const [capaPagina, setCapaPagina] = useState("");
  const [erroCapaPagina, setErroCapaPagina] = useState("");

  const [bannerPagina, setBannerPagina] = useState("");
  const [erroBannerPagina, setErroBannerPagina] = useState("");
  const [bannerPaginaObj, setBannerPaginaObj] = useState("");

  const [novoTituloSecao, setNovoTituloSecao] = useState("");
  const [erroNovoTituloSecao, setErroNovoTituloSecao] = useState("");

  const [novaDescricaoSecao, setNovaDescricaoSecao] = useState("");
  const [erroNovaDescricaoSecao, setErroNovaDescricaoSecao] = useState("");

  const [accessToken, setAccessToken] = useState("");
  const [bunnyUrlPublico, setBunnyUrlPublico] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/paginas/banners/`);
  const [alteracoesSalvas, setAlteracoesSalvas] = useState("")

  const [editRows, setEditRows] = useState(2);
  const [createRows, setCreateRows] = useState(1);

  const [processando, setProcessando] = useState(false);

  const stateSetters = {
    edit: { setDescription: setDescricaoPagina, setRows: setEditRows, minRows: 2 },
    create: { setDescription: setNovaDescricaoSecao, setRows: setCreateRows, minRows: 1 }
  };

  const handleTextChange = (e, mode) => {
    const { setDescription, setRows, minRows } = stateSetters[mode];
    setDescription(e.target.value);
    const numOfLines = e.target.value.split('\n').length;
    setRows(numOfLines > minRows ? numOfLines : minRows);
  };

  useEffect(() => {
    let isMounted = true;
    setProcessando(true)
    const fetchPagina = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/pagina/${pagina_id}`, { headers: header_geral });

        if (isMounted && response.status === 200) {
          setPagina(response.data);
          setTituloPagina(response.data.titulo)
          setDescricaoPagina(response.data.descricao)
          setCapaPagina(response.data.capaImagem)
          setBannerPagina(response.data.bannerImagem)
        } else {
          console.log(response)
        }
      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };

    const tryUpdateSlugs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/criar-slugs`, { headers: header_geral });
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          console.error(err.response.data);  // Log the server error message
        }
      }
    }

    const fetchSecoes = async () => {
      setProcessando(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secoes/${pagina_id}`, { headers: header_geral });
        if (!isMounted) return;
        setSecoes(response.data);

      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };
    // tryUpdateSlugs();
    fetchPagina();
    fetchSecoes();

    return () => { isMounted = false };
  }, [pagina_id]);

  function criarSlug(titulo) {
    const mapaAcentos = {
      'á': 'a', 'ã': 'a', 'à': 'a', 'â': 'a', 'é': 'e', 'ê': 'e', 'í': 'i', 'ó': 'o', 'õ': 'o', 'ô': 'o', 'ú': 'u', 'ç': 'c',
      'Á': 'a', 'Ã': 'a', 'À': 'a', 'Â': 'a', 'É': 'e', 'Ê': 'e', 'Í': 'i', 'Ó': 'o', 'Õ': 'o', 'Ô': 'o', 'Ú': 'u', 'Ç': 'c'
    };

    let slug = titulo.toLowerCase();
    slug = slug.replace(/[\s]/g, '-'); // substitui espaços por hífens
    slug = slug.replace(/[\W]/g, function (char) { // substitui caracteres especiais
      return mapaAcentos[char] || char;
    });
    slug = slug.replace(/[^a-z0-9-]/g, ''); // remove caracteres não alfanuméricos e não hífens
    return slug;
  }

  const atualizarPagina = async (capa = null, banner = null) => {
    setProcessando(true)
    setAlteracoesSalvas("Salvando alterações");
    if (!tituloPagina || !tituloPagina.length) {
      setErroTituloPagina("O título é obrigatório!")
      setProcessando(false)
      return
    }
    setErroTituloPagina("")

    if (!descricaoPagina || !descricaoPagina.length) {
      setErroDescricaoPagina("A descrição é obrigatória!")
      setProcessando(false)
      return
    }
    setErroDescricaoPagina("")
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/pagina/${pagina._id}`, {
        titulo: tituloPagina,
        descricao: descricaoPagina,
        capaImagem: capa ? capa : capaPagina,
        bannerImagem: banner ? banner : bannerPagina,
      }, { headers: header_geral });
      if (response.status === 200) {
        console.log(response.data.message);

        setAlteracoesSalvas("Alterações salvas com sucesso!");
        setPagina(response.data);

        setTimeout(() => setAlteracoesSalvas(""), 3000)
      } else {
        console.log(response);
        setAlteracoesSalvas("")
      }
    } catch (err) {
      setAlteracoesSalvas("")
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        alert(err.response.data.error)
      } else {
        console.error(err);
      }
    } finally {
      setProcessando(false)
    }
  };

  const uploadFile = async (event, tipo) => {
    setProcessando(true);
    const file = event.target.files[0];

    if (!file) {
      console.error("Nenhum arquivo selecionado");
      setProcessando(false);
      return;
    }

    // Obter a extensão do arquivo
    const ext = file.name.split('.').pop();

    try {
      // Fazer uma requisição ao backend para obter a URL assinada
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/upload/pagina/${tipo}/${pagina._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            ext: `.${ext}`, // Passar a extensão do arquivo
            contentType: file.type // Passar o tipo de conteúdo
          }
        }
      );

      const { uploadUrl, fileName } = response.data;

      // Realizar o upload direto para o Google Cloud Storage usando a URL assinada
      const uploadResponse = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type, // Definir o tipo de conteúdo
        },
      });

      if (uploadResponse.status === 200) {
        // Atualizar a URL do banner ou capa após o upload
        const publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;

        if (tipo === "capa") {
          setCapaPagina(publicUrl);
          await atualizarPagina(publicUrl, null);
        } else {
          setBannerPagina(publicUrl);
          await atualizarPagina(null, publicUrl);
        }

        toast.success("Upload realizado com sucesso");
        console.log('Upload realizado com sucesso:', publicUrl);
      } else {
        toast.error("Erro na tentativa de upload");
        console.error('Erro no upload:', uploadResponse);
      }
    } catch (error) {
      toast.error("Erro na tentativa de upload");
      console.error('Erro no upload:', error.response ? error.response.data : error.message);
    } finally {
      // Limpar o input de arquivo
      event.target.value = null;
      setProcessando(false);
    }
  };




  const [ordemBotoes, setOrdemBotoes] = useState(true)

  const alterarOrdem = async (secao_id, direcao) => {
    setProcessando(true)
    setOrdemBotoes(false)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secoes/ordem/${secao_id}`, { direcao }, { headers: header_geral });
      if (response.status === 200) {
        console.log('Ordem da seção alterada com sucesso!');
        // Após a alteração da ordem, faça uma nova requisição para buscar todos os cursos
        const responseSecoes = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secoes/${pagina_id}`, { headers: header_geral });

        if (responseSecoes.status === 200) {
          // Atualize o estado do seu componente com as novos cursos
          setSecoes(responseSecoes.data);

        } else {
          console.error('Erro ao buscar seção:', responseSecoes);
        }
        return response.data;
      } else {
        console.error('(TRY) Erro ao alterar a ordem da seção:', response);
      }
    } catch (err) {
      console.error('(CATCH) Erro ao alterar a ordem da seção:', err);
    } finally {
      setOrdemBotoes(true)
      setProcessando(false)
    }
  };


  const adicionarSecao = async () => {
    setProcessando(true)
    if (!novoTituloSecao) {
      setErroNovoTituloSecao("O título é obrigatório.");
      setProcessando(false)
      return;
    }
    setErroNovoTituloSecao("");

    /*if (!novaDescricaoSecao) {
      setErroNovaDescricaoSecao("A descrição é obrigatória.");
      return;
    }*/
    setErroNovaDescricaoSecao("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao`, {
        pagina_id: pagina._id,
        titulo: novoTituloSecao,
        codigo: criarSlug(novoTituloSecao),
        descricao: novaDescricaoSecao,
      }, { headers: header_geral });
      // Atualize a lista de seções após a criação bem-sucedida de uma nova seção
      setSecoes(prevSecoes => [...prevSecoes, response.data]);
      setNovoTituloSecao("");
      setNovaDescricaoSecao("");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        console.error(err.response.data);  // Log the server error message
        setErroNovoTituloSecao(err.response.data.titulo);
        setErroNovaDescricaoSecao(err.response.data.descricao);
      }
    } finally {
      setProcessando(false)
    }

  };

  const excluirSecao = async (secao_id, secao_titulo) => {
    setProcessando(true)
    if (!confirm("Você tem certeza que deseja deletar a seção '" + secao_titulo + "'? Esta ação não pode ser desfeita!")) {
      setProcessando(false)
      return
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao/${secao_id}`, { headers: header_geral });
      if (response.status === 200) {
        console.log(response.data.message);
        // Remova a seção da lista após a exclusão bem-sucedida
        setSecoes(prevSecoes => prevSecoes.filter(secao => secao._id !== secao_id));
        alert(response.data.message)
      } else {
        console.log(response)
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        alert(err.response.data.error)
      } else {
        console.log(err)
      }
    } finally {
      setProcessando(false)
    }
  };

  return (
    <div className="space-y-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden">
      <div className="w-full">
        {pagina &&
          <div>
            <div className='text-white bg-zinc-950 p-8'>
              <p className="text-md text-gray-400 mb-3">Disciplina</p>
              <h1 className='text-5xl'>{pagina.titulo}</h1>
              <p className="text-orange-500 hover:text-orange-600 active:text-orange-700 cursor-pointer mt-3 transition-all duration-100 ease-linear" onClick={() => history.push('/admin/master')}>Voltar</p>
            </div>

            <div className='px-14'>
              <p className='secaoTitulo'>Editar informações da Disciplina</p>

              <div className='space-y-8'>

                <div className='flex flex-col lg:flex-row gap-8'>
                  {/* Capa Section */}
                  <div className="relative w-full lg:w-1/3 border-2 border-orange-600 rounded-lg overflow-hidden">
                    {capaPagina && capaPagina.length ? (
                      <img id="img-pagina-capa" src={`${capaPagina}?timestamp=${new Date().getTime()}`} alt="Capa" className="w-full h-full object-cover transition-opacity duration-300 ease-in-out" />
                    ) : (
                      <p className='h-full text-gray-500 text-xl text-center p-5 rounded-md my-3'>
                        Nenhuma imagem de capa foi selecionada para esta disciplina até o momento!
                      </p>
                    )}
                    {erroCapaPagina && <span className="error-master">{erroCapaPagina}</span>}

                    {/* Trocar Capa Button */}
                    <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-70 rounded-lg px-4 py-2 sm:text-xs sm:text-md md:text-lg lg:text-xl'>
                      <input
                        type="file"
                        id="capaInput"
                        accept="image/*"
                        className='hidden disabled:opacity-50'
                        onChange={(e) => uploadFile(e, "capa")}
                        disabled={processando}
                      />
                      <div
                        className="text-white text-xl cursor-pointer hover:text-orange-600"
                        onClick={() => document.querySelector('#capaInput').click()}
                      >
                        Trocar Capa
                      </div>
                    </div>
                  </div>

                  {/* Banner Section */}
                  <div className="relative w-full lg:w-2/3 border-2 border-orange-600 rounded-lg overflow-hidden">
                    {bannerPagina && bannerPagina.length ? (
                      <img id="img-pagina-banner" src={`${bannerPagina}?timestamp=${new Date().getTime()}`} alt="Banner" className="w-full h-full object-cover transition-opacity duration-300 ease-in-out" />
                    ) : (
                      <p className='h-full text-gray-500 text-xl text-center p-5 rounded-md my-3'>
                        Nenhuma imagem de banner foi selecionada para esta disciplina até o momento!
                      </p>
                    )}
                    {erroBannerPagina && <span className="error-master">{erroBannerPagina}</span>}

                    {/* Trocar Banner Button */}
                    <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-70 rounded-lg px-4 py-2 sm:text-xs sm:text-md md:text-lg lg:text-xl'>
                      <input
                        type="file"
                        id="bannerInput"
                        accept="image/*"
                        className='hidden disabled:opacity-50'
                        onChange={(e) => uploadFile(e, "banner")}
                        disabled={processando}
                      />
                      <div
                        className="text-white text-xl cursor-pointer hover:text-orange-600"
                        onClick={() => document.querySelector('#bannerInput').click()}
                      >
                        Trocar Banner
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form Section */}
                <div className='mx-auto sm:w-2/3'>
                  <input
                    type="text"
                    value={tituloPagina}
                    disabled={processando}
                    onChange={e => setTituloPagina(e.target.value)}
                    className="input-master page-title-input w-full mb-4 disabled:opacity-50"
                    placeholder="Título"
                  />
                  {erroTituloPagina && <span className="error-master">{erroTituloPagina}</span>}

                  <textarea
                    value={descricaoPagina}
                    onChange={e => handleTextChange(e, 'edit')}
                    className="input-master page-title-input w-full mb-4 disabled:opacity-50"
                    placeholder="Descrição"
                    rows={editRows}
                    disabled={processando}
                  />
                  {erroDescricaoPagina && <span className="error-master">{erroDescricaoPagina}</span>}

                  <button
                    className='w-full salvarUpdate disabled:opacity-50'
                    onClick={() => atualizarPagina(null)}
                    disabled={processando}
                  >
                    Salvar Alterações
                  </button>
                  <p className='text-green-500 my-5 text-center transition-all'>{alteracoesSalvas}</p>
                </div>
              </div>


              <p className='secaoTitulo'>Lista de Cursos</p>

              <table className="tabela">
                <thead>
                  <tr>
                    {secoes.length > 1 &&
                      <th className='px-2 py-2 border border-neutral-300 text-left text-white w-fit'>Ordem</th>
                    }
                    <th>Título</th>
                    <th>Descrição</th>
                    {/*<th>Editar</th>*/}
                    <th>Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  {secoes.map((secao, index) => (
                    <tr key={index} onClick={() => {
                      localStorage.setItem('master-secao', secao.codigo)
                      history.push(`/admin/master-secao/${secao._id}`)
                    }}>
                      {secoes.length > 1 &&
                        <td className='px-2 py-2 border border-neutral-300 text-left text-white whitespace-pre-wrap w-fit'>
                          <span className='flex flex-col items-center'>
                            {index > 0 && ordemBotoes &&
                              <button type='button' disabled={processando} className='text-center disabled:opacity-50 hover:text-orange-600 active:text-orange-700' onClick={(event) => {
                                event.stopPropagation();  // Adicione esta linha
                                alterarOrdem(secao._id, -1);
                              }}>▲</button>
                            }

                            {index < secoes.length - 1 && ordemBotoes &&
                              <button type='button' disabled={processando} className='text-center disabled:opacity-50 hover:text-orange-600 active:text-orange-700' onClick={(event) => {
                                event.stopPropagation();  // Adicione esta linha
                                alterarOrdem(secao._id, 1);
                              }}>▼</button>
                            }
                          </span>
                        </td>
                      }
                      <td>{secao.titulo}</td>
                      <td>{secao.descricao}</td>
                      <td>
                        <button className="btnIcone disabled:opacity-50" onClick={(event) => {
                          event.stopPropagation();  // Adicione esta linha
                          excluirSecao(secao._id, secao.titulo);
                        }} disabled={processando}>
                          <svg width="30px" height="30px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#a1a1a1" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
                            <g strokeWidth="0">
                              <path transform="translate(-2.4, -2.4), scale(1.7999999999999998)" fill="#efefefaaaaaa" d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z" strokeWidth="0" />
                            </g>
                            <g strokeLinecap="round" strokeLinejoin="round" />
                            <g>
                              <path d="M10 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M14 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M4 7H20" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    {secoes.length > 1 &&
                      <td className='px-2 py-2 border border-neutral-300 text-left text-white w-fit'></td>
                    }
                    <td>
                      <input type="text" value={novoTituloSecao} disabled={processando} onChange={e => setNovoTituloSecao(e.target.value)} className="input-master disabled:opacity-50" placeholder="Título" />
                      {erroNovoTituloSecao && <span className="error-master">{erroNovoTituloSecao}</span>}
                    </td>
                    <td>
                      <textarea
                        value={novaDescricaoSecao}
                        onChange={e => handleTextChange(e, 'create')}
                        disabled={processando}
                        className="input-master disabled:opacity-50"
                        placeholder="Descrição"
                        rows={createRows}
                      />
                      {erroNovaDescricaoSecao && <span className="error-master">{erroNovaDescricaoSecao}</span>}
                    </td>
                    <td>
                      <button className="btnIcone disabled:opacity-50" onClick={adicionarSecao} disabled={processando}>
                        <svg width="30px" height="30px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#efefef">
                          <g strokeWidth="0" />
                          <g strokeLinecap="round" strokeLinejoin="round" />
                          <g>
                            <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#efefef" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.0574 1.25H11.9426C9.63424 1.24999 7.82519 1.24998 6.41371 1.43975C4.96897 1.63399 3.82895 2.03933 2.93414 2.93414C2.03933 3.82895 1.63399 4.96897 1.43975 6.41371C1.24998 7.82519 1.24999 9.63422 1.25 11.9426V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H12.0574C14.3658 22.75 16.1748 22.75 17.5863 22.5603C19.031 22.366 20.1711 21.9607 21.0659 21.0659C21.9607 20.1711 22.366 19.031 22.5603 17.5863C22.75 16.1748 22.75 14.3658 22.75 12.0574V11.9426C22.75 9.63423 22.75 7.82519 22.5603 6.41371C22.366 4.96897 21.9607 3.82895 21.0659 2.93414C20.1711 2.03933 19.031 1.63399 17.5863 1.43975C16.1748 1.24998 14.3658 1.24999 12.0574 1.25ZM3.9948 3.9948C4.56445 3.42514 5.33517 3.09825 6.61358 2.92637C7.91356 2.75159 9.62177 2.75 12 2.75C14.3782 2.75 16.0864 2.75159 17.3864 2.92637C18.6648 3.09825 19.4355 3.42514 20.0052 3.9948C20.5749 4.56445 20.9018 5.33517 21.0736 6.61358C21.2484 7.91356 21.25 9.62177 21.25 12C21.25 14.3782 21.2484 16.0864 21.0736 17.3864C20.9018 18.6648 20.5749 19.4355 20.0052 20.0052C19.4355 20.5749 18.6648 20.9018 17.3864 21.0736C16.0864 21.2484 14.3782 21.25 12 21.25C9.62177 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12C2.75 9.62177 2.75159 7.91356 2.92637 6.61358C3.09825 5.33517 3.42514 4.56445 3.9948 3.9948Z" fill="#efefef" />
                          </g>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div >
  )
}

export default MasterPaginaAdmin