
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom' // Importe o Route e o Switch
import { useSelector, useDispatch } from 'react-redux';

import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import MenuAdmin from '../components/menu-admin'
import CursoAdmin from '../components/admin/curso'
import AulaAdmin from '../components/admin/aula'

import MasterAdmin from '../components/admin/master/main'
import MasterPaginaAdmin from '../components/admin/master/master-pagina'
import MasterSecaoAdmin from '../components/admin/master/master-secao'
import MasterCursoAdmin from '../components/admin/master/master-curso'
import MasterAulaAdmin from '../components/admin/master/master-aula'

import TrilhaAdmin from '../components/admin/trilha/main';

// import Painel from '../components/admin/painel'
import Planos from '../components/admin/planos'
import UsuarioAdmin from '../components/admin/usuario'
import Agendamentos from '../components/admin/agendamentos'
import NotificacoesAdmin from '../components/admin/notificacoes'
import LiveAdmin from '../components/admin/live'
import ConfigAdmin from '../components/admin/configuracoes'
import ComunidadeAdmin from '../components/admin/comunidade'

import './admin.css'

const Admin = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  return (
    <div className='w-full'>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="flex flex-col w-full min-h-screen bg-black gap-1.25">
        <Helmet>
          <title>Admin - Simbium</title>
          <meta
            property="og:title"
            content="Admin - Simbium"
          />
        </Helmet>
        <div className='bg-neutral-900 flex flex-col font-poppins'>
          <div className='w-full flex flex-row md:p-5 gap-5'>
            {showMenu &&
              <>
                <MenuAdmin usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />

              </>
            }
            <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
              <Switch>
                <Route path="/admin/planos" component={Planos} />
              </Switch>
              <Switch>
                <Route path="/admin/cursos" component={CursoAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/comunidade" component={ComunidadeAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/aulas" component={AulaAdmin} />
              </Switch>

              <Switch>
                <Route path="/admin/master" component={MasterAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/master-pagina/:pagina_id" component={MasterPaginaAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/master-secao/:secao_id" component={MasterSecaoAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/master-curso/:curso_id" component={MasterCursoAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/master-aula/:aula_id" component={MasterAulaAdmin} />
              </Switch>

              <Switch>
                <Route path="/admin/trilha" component={TrilhaAdmin} />
              </Switch>

              <Switch>
                <Route path="/admin/usuarios" component={UsuarioAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/agendamentos" component={Agendamentos} />
              </Switch>
              <Switch>
                <Route path="/admin/notificacoes" component={NotificacoesAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/live" component={LiveAdmin} />
              </Switch>
              <Switch>
                <Route path="/admin/configuracoes" component={ConfigAdmin} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div >
  )
}

export default Admin