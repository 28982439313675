import React, { useEffect, useState } from 'react'
import { span, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import axios from 'axios';
import { BanknotesIcon, BuildingOffice2Icon, BuildingOfficeIcon, ChartBarSquareIcon, ChatBubbleLeftEllipsisIcon, HomeIcon, InboxStackIcon, PlayCircleIcon, PuzzlePieceIcon, SparklesIcon, Square3Stack3DIcon, Squares2X2Icon, UserCircleIcon, UserGroupIcon, UserPlusIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const Menu = ({ usuarioAtual, showMenu, setShowMenu }) => {
    const location = useLocation();
    const [paginaAtiva, setPaginaAtiva] = useState('')
    const [topMenuItems, setTopMenuItems] = useState('')

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [secoesPagina, setSecoesPagina] = useState([]);
    const [processando, setProcessando] = useState(false);

    const mixPanelComunidadeAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Comunidade | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const fetchData = async () => {
        const data = await fetchConteudo('secao-pagina-com-paginas-home');
        if (data) {
            setSecoesPagina(data.secoesComPaginas);
            // console.log("Seções com pagina: ", data.secoesComPaginas)
        }
    };

    useEffect(() => {
        // Função para manipular o scroll
        const handleScroll = () => {
            setTopMenuItems(window.scrollY);
            // console.log("SCROLL Y: ", window.scrollY)
        };

        // Função assíncrona para buscar os dados
        const fetchDataAsync = async () => {
            await fetchData();
        };

        // Adicionar evento de scroll
        window.addEventListener('scroll', handleScroll);

        // Chamar a função de busca de dados
        fetchDataAsync();

        // Limpar o evento ao desmontar o componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const fetchConteudo = async (opcao) => {
        setProcessando(true)
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/${opcao}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                return response.data;
            } else {
                // toast.error("Erro ao tentar buscar as seções com páginas");
                console.log("Resposta do servidor: ", response);
                return null;
            }
        } catch (err) {
            // toast.error("Erro ao tentar buscar as seções com páginas");
            console.error("Erro da requisição: ", err);
            return null;
        } finally {
            setProcessando(false)
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    useEffect(() => {
        if (location) setPaginaAtiva(location.pathname)
    }, [location])

    return (
        <div className="fixed z-40 top-0 left-0 w-full lg:w-1/4 lg:ml-5 lg:mt-24 lg:h-[85vh] shadow-lg shadow-neutral-950 h-fit bg-neutral-800 rounded-lg m-0 pb-2 lg:mx-auto overflow-y-auto select-none">
            <div className='block lg:hidden min-w-[30px] w-8 m-5 space-y-1.5 group cursor-pointer' onClick={() => setShowMenu(!showMenu)}>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
            </div>
            <dl className={`w-full h-fit divide-neutral-700 text-neutral-200 text-sm space-y-2`}>
                <dd className={`m-2`}>
                    <a href="/" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <HomeIcon className='w-4 h-4' />
                        <span>Home</span>
                    </a>
                </dd>

                <dd
                    className={`rounded-lg ${paginaAtiva.includes('conteudos') ? 'text-neutral-200 bg-neutral-700' : ''}`}

                >
                    <div className='m-4 truncate flex flex-row items-center gap-3 cursor-pointer' onClick={toggleDropdown}>
                        <Square3Stack3DIcon className='w-4 h-4' />
                        <span>Conteúdos</span>
                        <span className={`${dropdownVisible ? 'rotate-180' : ''} transition-all`}>
                            <ChevronDownIcon className='w-5 h-5' />
                        </span>
                    </div>
                    <dl className={`w-full p-2 space-y-1 bg-neutral-900 ${dropdownVisible ? 'block' : 'hidden'}`}>
                        {secoesPagina ? (
                            secoesPagina.length > 0 ? (
                                secoesPagina.map(secao => (
                                    <div key={secao._id}>
                                        <dt className='font-extrabold'>{secao.titulo}</dt>
                                        {secao.paginas && secao.paginas.length > 0 ? (
                                            secao.paginas.map(pagina => (
                                                <dd key={pagina._id} className="m-2">
                                                    <a
                                                        href={`/master/${pagina.codigo}`}
                                                        className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva.includes(pagina.codigo) ? 'text-orange-500' : ''}`}
                                                    >
                                                        <SparklesIcon className="w-4 h-4 flex-shrink-0" />
                                                        <span className="truncate">{pagina.titulo}</span>
                                                    </a>
                                                </dd>

                                            ))
                                        ) : (
                                            <p className='text-xs text-neutral-500 px-3'>Não há páginas para esta seção</p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className='text-xs text-neutral-500 px-3'>Desculpe, não conseguimos carregar os conteúdos. Por favor, recarregue a página...</p>
                            )
                        ) : (
                            <p className='text-xs text-neutral-500 px-3'>Desculpe, não conseguimos carregar os conteúdos. Por favor, recarregue a página...</p>
                        )}

                        <dt className='font-extrabold'>Outros</dt>
                        <dd className='m-3'>
                            <a href="/shorts" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/shorts' ? 'text-orange-50 bg-orange-500' : ''}`}>
                                <PlayCircleIcon className='w-4 h-4' />
                                <span>Shorts</span>
                            </a>
                        </dd>
                    </dl>

                </dd>

                <dd className="m-2">
                    <a href="/consultorias" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/consultorias' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <ChatBubbleLeftEllipsisIcon className='w-4 h-4' />
                        <span>Consultorias</span>
                    </a>
                </dd>

                {/*<dd className="m-2">
                    <a href="/meus-colaboradores" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/meus-colaboradores' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <BuildingOfficeIcon className='w-4 h-4' />
                        <span>Colaboradores</span>
                    </a>
                </dd>*/}

                <dd className="m-2">
                    <a href={`${process.env.REACT_APP_COMUNIDADE_URL}`} target="_blank" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900`} onClick={() => mixPanelComunidadeAcesso()}>
                        <UserGroupIcon className='w-4 h-4' />
                        <span>Comunidade</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/produtos-catalogados" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/produtos-catalogados' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <Squares2X2Icon className='w-4 h-4' />
                        <span>Produtos Catalogados</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/marketplace" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/marketplace' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <ChartBarSquareIcon className='w-4 h-4' />
                        <span>Marketplace de Serviços</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/importacao-compartilhada" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/importacao-compartilhada' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <InboxStackIcon className='w-4 h-4' />
                        <span>Importe com a Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/franquia-simbium" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/franquia-simbium' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <BuildingOffice2Icon className='w-4 h-4' />
                        <span>Franquia Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/emprestimo-simbium" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/emprestimo-simbium' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <BanknotesIcon className='w-4 h-4' />
                        <span>Empréstimo Simbium</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/fornecedores-e-parceiros" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/fornecedores-e-parceiros' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <UserPlusIcon className='w-4 h-4' />
                        <span>Seja um Parceiro</span>
                    </a>
                </dd>

                <dd className="m-2">
                    <a href="/seja-consultor" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/seja-consultor' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <UserCircleIcon className='w-4 h-4' />
                        <span>Seja um Consultor</span>
                    </a>
                </dd>

                {/*<dd className="m-2">
                    <a href="/shorts" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/shorts' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <PlayCircleIcon className='w-4 h-4' />
                        <span>Shorts</span>
                    </a>
                </dd>*/}

                {/*<dd className="m-2">
                    <a href="/ia" className={`p-2 flex flex-row items-center gap-3 rounded-lg hover:bg-neutral-700 active:bg-neutral-900 ${paginaAtiva === '/ia' ? 'text-orange-50 bg-orange-500' : ''}`}>
                        <PuzzlePieceIcon className='w-4 h-4' />
                        <span>IA da Simbium</span>
                    </a>
                </dd>*/}
            </dl>
        </div>
    )
}

export default Menu