import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './menu-admin.css'
import { QueueListIcon } from '@heroicons/react/24/outline'

const MenuAdmin = (props) => {
  const history = useHistory()
  const location = useLocation()
  const current = location.pathname.split('/');

  useEffect(() => {
    if (location.pathname === '/admin/' || location.pathname === '/admin') {
      history.push('/admin/master')
    }

  }, [location])

  return (
    <div className={`menu-admin-container`}>
      <div className="menu-admin-menu">
        <div onClick={() => {
          history.push('/admin/master')
        }}
          className={`menu-admin-container3 ${(current.includes("master") || current.includes("master-pagina") || current.includes("master-secao") || current.includes("master-curso") || current.includes("master-aula")) ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 512 512" className="menu-admin-icon04">
            <g>
              <path className="st0" d="M102.49,0c0,27.414,0,104.166,0,137.062c0,112.391,99.33,156.25,153.51,156.25
                c54.18,0,153.51-43.859,153.51-156.25c0-32.896,0-109.648,0-137.062H102.49z M256.289,50.551l-68.164,29.768v98.474l-0.049,19.53
                c-0.526-0.112-47.274-10.112-47.274-78.391c0-28.17,0-69.6,0-69.6h60.385L256.289,50.551z"/>
              <polygon className="st0" points="315.473,400.717 291.681,367.482 279.791,318.506 256,322.004 232.209,318.506 220.314,367.482 
                205.347,388.394 196.527,400.476 196.699,400.476 196.527,400.717 	"/>
              <polygon className="st0" points="366.93,432.24 366.93,432 145.07,432 145.07,511.598 145.07,511.76 145.07,511.76 145.07,512 
                366.93,512 366.93,432.402 366.93,432.24 	"/>
              <path className="st0" d="M511.638,96.668c-0.033-1.268-0.068-2.336-0.068-3.174V45.1h-73.889v38.736h35.152v9.658
                c0,1.127,0.037,2.557,0.086,4.258c0.389,13.976,1.303,46.707-21.545,70.203c-5.121,5.266-11.221,9.787-18.219,13.613
                c-3.883,17.635-10.109,33.564-18.104,47.814c26.561-6.406,48.026-17.898,64.096-34.422
                C513.402,159.734,512.121,113.918,511.638,96.668z"/>
              <path className="st0" d="M60.625,167.955c-22.848-23.496-21.934-56.227-21.541-70.203c0.047-1.701,0.082-3.131,0.082-4.258v-9.658
                h34.842h0.07l0,0h0.24V45.1H0.43v48.394c0,0.838-0.032,1.906-0.068,3.174c-0.482,17.25-1.76,63.066,32.494,98.293
                c16.068,16.524,37.531,28.014,64.092,34.422c-7.996-14.25-14.22-30.182-18.103-47.816C71.846,177.74,65.746,173.221,60.625,167.955
                z"/>
            </g>
          </svg>
          <span className="menu-admin-text2">Master</span>
        </div>

        <div onClick={() => {
          history.push('/admin/trilha')
        }}
          className={`menu-admin-container3 ${(current.includes("trilha")) ? 'activeMenu text-orange-500' : 'text-white'}`}
        >
          <QueueListIcon className='w-5 h-5' />
          <span className="menu-admin-text2">Trilha</span>
        </div>

        <div
          onClick={() => {
            history.push('/admin/planos')
          }}
          className={`menu-admin-container1 ${current.includes("planos") ? 'activeMenu' : ''}`}
        >
          <svg className='menu-admin-icon08' viewBox="0 0 32 32">
            <title>paper-roll</title>
            <path d="M0 32h20v-17.088q1.92 1.088 4 1.088 3.328 0 5.664-2.336t2.336-5.664-2.336-5.632-5.664-2.368h-16q-3.296 0-5.664 2.368t-2.336 5.632v24zM4 28v-20q0-1.632 1.184-2.816t2.816-1.184h9.12q-1.12 1.92-1.12 4v20h-12zM6.016 26.016h8v-2.016h-8v2.016zM6.016 20h8v-1.984h-8v1.984zM6.016 14.016h8v-2.016h-8v2.016zM6.016 8h8v-1.984h-8v1.984zM20 8q0-1.632 1.184-2.816t2.816-1.184 2.816 1.184 1.184 2.816-1.184 2.848-2.816 1.152-2.816-1.152-1.184-2.848z"></path>
          </svg>
          <span className="menu-admin-text">Planos</span>
        </div>
        {/*<div
          onClick={() => {
            history.push('/admin/cursos')
          }}
          className={`menu-admin-container2 ${current.includes("cursos") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon02">
            <path
              d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text1">Cursos</span>
        </div>
        */}
        <div onClick={() => {
          history.push('/admin/aulas')
        }}
          className={`menu-admin-container3 ${current.includes("aulas") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon04">
            <path
              d="M598 256v-86h-172v86h172zM854 256q36 0 60 25t24 61v468q0 36-24 61t-60 25h-684q-36 0-60-25t-24-61v-468q0-36 24-61t60-25h172v-86q0-36 24-60t60-24h172q36 0 60 24t24 60v86h172z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text2">Shorts</span>
        </div>

        <div
          onClick={() => {
            history.push('/admin/notificacoes')
          }}
          className={`menu-admin-container4 ${current.includes("notificacoes") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon06">
            <path
              d="M768 682l86 86v42h-684v-42l86-86v-212q0-100 51-174t141-96v-30q0-26 18-45t46-19 46 19 18 45v30q90 22 141 96t51 174v212zM512 938q-36 0-61-24t-25-60h172q0 34-26 59t-60 25z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text3">Notificações</span>
        </div>
        <div
          onClick={() => {
            history.push('/admin/usuarios')
          }}
          className={`menu-admin-container5 ${current.includes("usuarios") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon08">
            <path
              d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text4">Usuários</span>
        </div>
        <div
          onClick={() => {
            history.push('/admin/agendamentos')
          }}
          className={`menu-admin-container5 ${current.includes("agendamentos") ? 'activeMenu' : ''}`}
        >
          <svg className='menu-admin-icon08' viewBox="0 0 24 24">
            <path d="M16 3.98999H8C6.93913 3.98999 5.92178 4.41135 5.17163 5.1615C4.42149 5.91164 4 6.92912 4 7.98999V17.99C4 19.0509 4.42149 20.0682 5.17163 20.8184C5.92178 21.5685 6.93913 21.99 8 21.99H16C17.0609 21.99 18.0783 21.5685 18.8284 20.8184C19.5786 20.0682 20 19.0509 20 17.99V7.98999C20 6.92912 19.5786 5.91164 18.8284 5.1615C18.0783 4.41135 17.0609 3.98999 16 3.98999Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 2V7" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 2V7" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 16H14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12H16" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="menu-admin-text4">Agendamentos</span>
        </div>
        <div
          onClick={() => {
            history.push('/admin/comunidade')
          }}
          className={`menu-admin-container6 ${current.includes("comunidade") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1152 1024" className="menu-admin-icon10">
            <path
              d="M480 0v0c265.096 0 480 173.914 480 388.448s-214.904 388.448-480 388.448c-25.458 0-50.446-1.62-74.834-4.71-103.106 102.694-222.172 121.108-341.166 123.814v-25.134c64.252-31.354 116-88.466 116-153.734 0-9.106-0.712-18.048-2.030-26.794-108.558-71.214-177.97-179.988-177.97-301.89 0-214.534 214.904-388.448 480-388.448zM996 870.686c0 55.942 36.314 104.898 92 131.772v21.542c-103.126-2.318-197.786-18.102-287.142-106.126-21.14 2.65-42.794 4.040-64.858 4.040-95.47 0-183.408-25.758-253.614-69.040 144.674-0.506 281.26-46.854 384.834-130.672 52.208-42.252 93.394-91.826 122.414-147.348 30.766-58.866 46.366-121.582 46.366-186.406 0-10.448-0.45-20.836-1.258-31.168 72.57 59.934 117.258 141.622 117.258 231.676 0 104.488-60.158 197.722-154.24 258.764-1.142 7.496-1.76 15.16-1.76 22.966z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text5">Comunidade</span>
        </div>
        <div
          onClick={() => {
            history.push('/admin/configuracoes')
          }}
          className={`menu-admin-container7 ${current.includes("configuracoes") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon12">
            <path
              d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text6">Configurações</span>
        </div>
        <div onClick={() => {
          history.push('/admin/live')
        }}
          className={`menu-admin-container8 ${current.includes("live") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-admin-icon14">
            <path
              d="M384 512c0-70.692 57.308-128 128-128s128 57.308 128 128c0 70.692-57.308 128-128 128s-128-57.308-128-128zM664.348 230.526c99.852 54.158 167.652 159.898 167.652 281.474s-67.8 227.316-167.652 281.474c44.066-70.126 71.652-170.27 71.652-281.474s-27.586-211.348-71.652-281.474zM288 512c0 111.204 27.584 211.348 71.652 281.474-99.852-54.16-167.652-159.898-167.652-281.474s67.8-227.314 167.652-281.474c-44.068 70.126-71.652 170.27-71.652 281.474zM96 512c0 171.9 54.404 326.184 140.652 431.722-142.302-90.948-236.652-250.314-236.652-431.722s94.35-340.774 236.652-431.722c-86.248 105.538-140.652 259.822-140.652 431.722zM787.352 80.28c142.298 90.946 236.648 250.312 236.648 431.72s-94.35 340.774-236.648 431.72c86.244-105.536 140.648-259.82 140.648-431.72s-54.404-326.184-140.648-431.72z"
              className=""
            ></path>
          </svg>
          <span className="menu-admin-text7">Live</span>
        </div>
      </div>
    </div>
  )
}

export default MenuAdmin
