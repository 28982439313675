import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Trilha = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const onboarding = usuarioAtual?.onboarding || {};

    const [videoBoasVindas, setVideoBoasVindas] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        const getVideoBoasVindas = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                setVideoBoasVindas(data?.boas_vindas?.url || "");
            } catch (err) {
                console.error("Erro ao obter o vídeo de boas-vindas:", err);
            }
        };
        getVideoBoasVindas();
    }, []);

    return (
        <div className="w-11/12 mx-auto space-y-5 text-white mb-10">
            <div className='bg-neutral-800 rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-5 divide-y lg:divide-y-0 lg:divide-x divide-neutral-900 '>
                <div className='p-0 lg:p-5 lg:rounded-lg overflow-hidden'>
                    <video src={videoBoasVindas + `?timestamp=${new Date().getHours()}` || ""} className='w-full h-full' controls autoPlay muted controlsList="nodownload"
                        onContextMenu={() => { return false }}></video>
                </div>
                <div className={`p-10 bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: "url('/trilha-background-light.svg')" }}>
                    {usuarioAtual.tipo === 'guest' ?
                        <div className='w-2/3 mx-auto h-full flex flex-col gap-5 items-center justify-center align-middle text-white text-center'>
                            <h1 className='font-extrabold text-xl md:text-2xl lg:text-3xl font-poppins'>Defina sua meta</h1>
                            <p className='text-sm md:text-lg lg-text-xl italic'>Descubra qual trilha melhor se encaixa no meu momento</p>
                            <button className='bg-orange-500 rounded-full w-1/2 px-5 py-3 font-extrabold text-xs md:text-md xl:text-xl hover:bg-orange-700 active:bg-white active:text-orange-500'>Começar</button>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Trilha;
