import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import Cookies from 'js-cookie';


import './login.css'


const Login = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [nome, setNome] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [modoCadastro, setModoCadastro] = useState(search === "?cadastro=true");
  const [whatsSuporte, setWhatsSuporte] = useState('')
  const [loading, setLoading] = useState(false);

  const formatarTelefone = (input) => {
    const numericValue = input.replace(/\D/g, '');

    const maxLength = 11;
    if (numericValue.length > maxLength) {
      return whatsapp;
    }

    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += `(${numericValue[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${numericValue[i]}`;
      } else if (i === 6 && numericValue.length <= 10) {
        formattedValue += `-${numericValue[i]}`;
      } else if (i === 7 && numericValue.length === 11) {
        formattedValue += `-${numericValue[i]}`;
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  };


  const handleTelefoneChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatarTelefone(inputValue);
    setWhatsapp(formattedValue);
  };


  const emailRedefinirSenha = async () => {
    if (email === '') {
      toast.error('Insira seu e-mail de cadastro para recuperar a senha.')
    } else {

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/redefinir-senha`, {
          email
        })

        toast.success('Foi enviado um e-mail com instruções para redefinir sua senha.')

      } catch (err) {
        toast.error('Algo deu errado. Verifique se digitou seu e-mail corretamente.')
      }

    }

  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const verificarEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleCadastro = async () => {

    if (nome.trim().split(" ").length === 1) {
      toast.error("Insira o nome completo")
      return;
    }

    if (!verificarEmail(email)) {
      toast.error("E-mail inválido")
      return;
    }

    if (whatsapp.length < 14) {
      toast.error("Digite um número de telefone válido")
      return;
    }

    if (nome === '' || email === '' || whatsapp === '') {
      toast.error('Todos campos são obrigatórios')
      return;
    }

    setLoading(true);

    try {

      const indicadorId = Cookies.get('indicadorId');
      let dataBody = {
        email,
        nome,
        telefone: whatsapp
      }

      if (indicadorId) {
        dataBody.indicador = indicadorId
      }

      const result = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/cadastro', dataBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      toast.success('Cadastro realizado com sucesso. Em instantes você irá receber um e-mail com seus dados de acesso.')
      setModoCadastro(false)

    } catch (err) {
      console.log(err)
      if (err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error)
      } else {
        toast.error('Algo deu errado com seu cadastro.')
      }

    }


    setLoading(false)
  };

  const handleLogin = async () => {
    if (!search.includes("redefinir-senha")) {
      try {
        const { data: { token, usuario } } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/login`,
          { email, senha: password }
        );

        // Armazena o token e o ID do usuário
        localStorage.setItem('token', token);
        localStorage.setItem('user', usuario._id);

        dispatch(setUsuario(usuario));

        window.location.replace('/');
      } catch (error) {
        setEmail('');
        setPassword('');
        const mensagemErro = error?.response?.data?.mensagem || "Algo deu errado";
        toast.error(mensagemErro);
      }
    } else {
      if (!password) {
        toast.error('A senha não pode ficar vazia');
        return;
      }

      if (password !== confirmarSenha) {
        toast.error('As duas senhas não coincidem');
        return;
      }

      try {
        const regex = /redefinir-senha=([^&]+)/;
        const match = search.match(regex);

        if (!match || !match[1]) {
          toast.error('Token inválido');
          return;
        }

        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/alterar-senha-token`,
          { token: match[1], newPassword: confirmarSenha }
        );

        if (data?.token) {
          localStorage.setItem('token', data.token);
        }

        toast.success('Senha alterada com sucesso!');
        window.location.replace('/login');
      } catch (error) {
        const mensagemErro = error?.response?.data?.mensagem || 'Algo deu errado';
        toast.error(mensagemErro);
        setPassword('');
        setConfirmarSenha('');
        setEmail('');
        window.location.replace('/login');
      }
    }
  };


  useEffect(() => {

    setEmail('')
    setPassword('')
    setConfirmarSenha('')
    setNome('')
    setWhatsapp('')

    /*if(!modoCadastro) {
      history.replace(window.location.pathname);
    }*/

  }, [modoCadastro])

  useEffect(() => {

    let isMounted = true

    const getWhatsApp = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + "/config")


        if (response.data && response.data.whatsapp) {
          setWhatsSuporte(response.data.whatsapp.replaceAll(/\D/g, ""));
        }

      } catch (err) {

      }
    }


    getWhatsApp()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Simbium</title>
        <meta
          property="og:title"
          content="Login - Simbium"
        />
      </Helmet>
      <div className="login-container1">
        <div className="login-logo">
          <img
            alt="image"
            src="/https___b3a6390b7a66b161ddd9c8f9e9c8f2de.cdn.bubble.io_f1689088670051x906951525380208500_logo_simbium-1500h.png"
            className="login-image"
          />
        </div>
        <div className="login-preencher">
          <img
            alt="image"
            src="/image%209-1500h.png"
            className="login-image1"
          />
          <div className="login-container2">
            <span className="login-text">{!search.includes("redefinir-senha") ? modoCadastro ? 'Crie sua conta' : 'Faça seu Login' : 'Redefinir Senha'}</span>
            <div className="login-inputs">
              {!search.includes("redefinir-senha") && modoCadastro && (
                <div className="login-container3">
                  <svg
                    viewBox="0 0 731.4285714285713 1024"
                    className="login-icon"
                  >
                    <path d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Nome"
                    className="login-textinput input"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
              )}
              {!search.includes("redefinir-senha") && (
                <div className="login-container3">
                  <svg viewBox="0 0 1024 1024" className="login-icon">
                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                  </svg>
                  <input
                    type="text"
                    placeholder="Email"
                    className="login-textinput input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
              {!search.includes("redefinir-senha") && modoCadastro && (
                <div className="login-container3">
                  <svg
                    viewBox="0 0 804.5714285714286 1024"
                    className="login-icon"
                  >
                    <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
                  </svg>

                  <input
                    type="text"
                    placeholder="Telefone/WhatsApp"
                    className="login-textinput input"
                    value={whatsapp}
                    onChange={handleTelefoneChange}
                  />
                </div>
              )}
              {!modoCadastro && (
                <div className="login-container4">
                  <svg
                    viewBox="0 0 658.2857142857142 1024"
                    className="login-icon2"
                  >
                    <path d="M182.857 438.857h292.571v-109.714c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286v109.714zM658.286 493.714v329.143c0 30.286-24.571 54.857-54.857 54.857h-548.571c-30.286 0-54.857-24.571-54.857-54.857v-329.143c0-30.286 24.571-54.857 54.857-54.857h18.286v-109.714c0-140.571 115.429-256 256-256s256 115.429 256 256v109.714h18.286c30.286 0 54.857 24.571 54.857 54.857z"></path>
                  </svg>
                  <input
                    type="password"
                    placeholder="Senha"
                    className="login-textinput1 input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              )}
              {search.includes("redefinir-senha") && (
                <div className="login-container4">
                  <svg viewBox="0 0 658.2857142857142 1024" className="login-icon2">
                    <path d="M182.857 438.857h292.571v-109.714c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286v109.714zM658.286 493.714v329.143c0 30.286-24.571 54.857-54.857 54.857h-548.571c-30.286 0-54.857-24.571-54.857-54.857v-329.143c0-30.286 24.571-54.857 54.857-54.857h18.286v-109.714c0-140.571 115.429-256 256-256s256 115.429 256 256v109.714h18.286c30.286 0 54.857 24.571 54.857 54.857z"></path>
                  </svg>
                  <input type="password" placeholder="Confirmar Senha" className="login-textinput1 input" value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                  />
                </div>
              )}
              {!search.includes("redefinir-senha") && !modoCadastro && (
                <span className="login-text1" onClick={emailRedefinirSenha}>Esqueceu sua senha?</span>
              )}
            </div>
            <div className="login-container5">
              {!modoCadastro && (
                <div className="login-b-entrar" onClick={() => handleLogin()}>
                  {!search.includes("redefinir-senha") && (
                    <svg viewBox="0 0 1024 1024" className="login-icon4">
                      <path d="M640 170.667h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v597.333c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h170.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-597.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM537.003 469.333h-409.003c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h409.003l-140.501 140.501c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l213.333-213.333c4.096-4.096 7.168-8.789 9.259-13.824s3.243-10.539 3.243-16.341c0-5.547-1.067-11.136-3.243-16.341-2.091-5.035-5.163-9.728-9.259-13.824l-213.333-213.333c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                    </svg>
                  )}
                  <span className="login-text2">{search.includes("redefinir-senha") ? 'Alterar senha' : 'Entrar'}</span>
                </div>
              )}
              {!search.includes("redefinir-senha") && (
                <button disabled={loading} className={`login-b-entrar ${!modoCadastro ? 'cadastro' : ''}`} onClick={() => !modoCadastro ? setModoCadastro(true) : handleCadastro()}>
                  <span className="login-text2">{modoCadastro ? loading ? 'Cadastrando...' : 'Cadastre-se' : 'Não tem conta? Crie aqui'}</span>
                </button>
              )}
              <div className="login-container6" onClick={() => {
                modoCadastro ?
                  setModoCadastro(false) :
                  window.open(`https://api.whatsapp.com/send?phone=${whatsSuporte}`)
              }}>
                {!modoCadastro && (
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="login-icon6"
                  >
                    <path d="M512 786.286v-109.714c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v109.714c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM658.286 402.286c0-104.571-109.714-182.857-208-182.857-93.143 0-162.857 40-212 121.714-5.143 8-2.857 18.286 4.571 24l75.429 57.143c2.857 2.286 6.857 3.429 10.857 3.429 5.143 0 10.857-2.286 14.286-6.857 26.857-34.286 38.286-44.571 49.143-52.571 9.714-6.857 28.571-13.714 49.143-13.714 36.571 0 70.286 23.429 70.286 48.571 0 29.714-15.429 44.571-50.286 60.571-40.571 18.286-96 65.714-96 121.143v20.571c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286v0c0-13.143 16.571-41.143 43.429-56.571 43.429-24.571 102.857-57.714 102.857-144.571zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                )}
                <span className="login-text3">{modoCadastro ? 'Já tenho uma conta' : 'Fale com o suporte'}</span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
