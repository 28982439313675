import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import './master.css'
import { toast } from 'react-toastify'

const MasterAulaAdmin = (props) => {
  const history = useHistory();
  const { aula_id } = useParams();
  const [aula, setAula] = useState(null);
  const [tituloAula, setTituloAula] = useState("");
  const [descricaoAula, setDescricaoAula] = useState("");
  const [tipoVideoAula, setTipoVideoAula] = useState('cloud');
  const [videoAula, setVideoAula] = useState("");
  const [alteracoesSalvas, setAlteracoesSalvas] = useState("");
  const [erro, setErro] = useState("");
  const [editRows, setEditRows] = useState(2);
  const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const bunnyUrlPublico = `${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/aulas/videos/`;

  const [processando, setProcessando] = useState(false);

  useEffect(() => {
    const fetchAula = async () => {
      setProcessando(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aula/${aula_id}`, { headers: header_geral });
        setAula(response.data);
        setTituloAula(response.data.titulo);
        setDescricaoAula(response.data.descricao);
        setVideoAula(response.data.video);
        setTipoVideoAula(response.data.video.includes('youtube.com') ? 'youtube' : 'cloud');
      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };
    fetchAula();
  }, [aula_id]);

  const handleTextChange = (e) => {
    setDescricaoAula(e.target.value);
    const numOfLines = e.target.value.split('\n').length;
    setEditRows(numOfLines > 2 ? numOfLines : 2);
  };

  const atualizarAula = async (file = null) => {
    setProcessando(true)
    setAlteracoesSalvas("Salvando alterações");

    if (!tituloAula) {
      toast.error("Adicione um título da aula!")
      setProcessando(false)
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/aula/${aula._id}`, {
        titulo: tituloAula,
        descricao: descricaoAula,
        video: file ? file : videoAula,
      }, { headers: header_geral });

      if (response.status === 200) {
        setAlteracoesSalvas("Alterações salvas com sucesso!");
        setTimeout(() => setAlteracoesSalvas(""), 3000);
      } else {
        setAlteracoesSalvas("");
      }
    } catch (err) {
      setAlteracoesSalvas("");
      console.error(err);
    } finally {
      setProcessando(false)
    }
  };

  const uploadAula = async (event) => {
    setProcessando(true);
    setAlteracoesSalvas("Fazendo upload do vídeo... (Aguarde)");
    const file = event.target.files[0];

    if (!file) {
      console.error("Nenhum vídeo inserido!");
      setProcessando(false);
      return;
    }

    /*
    const maxSizeMB = 5000; // 5 GB em MB
    const fileSizeMB = file.size / (1024 * 1024); // Tamanho do arquivo em MB

    if (fileSizeMB > maxSizeMB) {
      console.error("O arquivo excede o tamanho máximo permitido de 5 GB.");
      setErro("O arquivo excede o tamanho máximo permitido de 5 GB.");
      setTimeout(() => setErro(""), 5000);
      setProcessando(false);
      return;
    }
      */

    const ext = file.name.split('.').pop();

    try {
      // Solicitar a URL assinada do backend
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/upload/aula/video-aula/${aula._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            ext: `.${ext}`, // Passar a extensão do arquivo
            contentType: file.type, // Passar o tipo de conteúdo
          },
        }
      );

      const { uploadUrl, fileName } = response.data;

      // Fazer o upload direto para o Google Cloud Storage usando a URL assinada
      const uploadResponse = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type, // Definir o tipo de conteúdo
        },
      });

      if (uploadResponse.status === 200) {
        setVideoAula("")
        // Atualizar a URL do vídeo da aula após o upload
        const publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;
        //setAula((prevAula) => ({ ...prevAula, video: publicUrl }));
        setVideoAula(publicUrl);
        await atualizarAula(publicUrl); // Atualizar a aula no backend com a URL pública

        toast.success("Upload realizado com sucesso");
        console.log('Upload realizado com sucesso:', publicUrl);
      } else {
        toast.error("Erro na tentativa de upload");
        console.error('Erro no upload:', uploadResponse);
      }
    } catch (error) {
      toast.error("Erro na tentativa de upload");
      console.error('Erro no upload:', error.response ? error.response.data : error.message);
    } finally {
      // Limpar o input de arquivo
      event.target.value = null;
      setProcessando(false);
    }
  };




  const getFormat = (videoUrl) => {
    // Divide a URL pelo ponto e retorna o último item da lista
    const parts = videoUrl.split('.');
    return parts[parts.length - 1];
  }


  return (
    <div className="space-y-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden">
      <div className="w-full">
        {aula &&
          <div>
            <div className='text-white bg-zinc-950 p-8'>
              <p className="text-md text-gray-400 mb-3">Disciplina {'>'} Curso {'>'} Módulo {'>'} Aula</p>
              <h1 className='text-5xl'>{aula.titulo}</h1>
              <p className="text-orange-500 hover:text-orange-600 active:text-orange-700 cursor-pointer mt-3 transition-all duration-100 ease-linear" onClick={() => history.push(`/admin/master-curso/${aula.curso}`)}>Voltar</p>
            </div>

            <div className='px-14'>
              <p className='secaoTitulo'>Editar informações da Aula</p>

              <div className='flex flex-col sm:flex-row items-center align-middle gap-5'>
                <div className="flex flex-col items-center align-middle w-full sm:w-1/2 border-dashed border-2 border-orange-600 rounded-lg overflow-hidden">
                  {videoAula && videoAula.length ?
                    (tipoVideoAula === 'cloud' ?
                      <video
                        controls
                        className="w-full h-[400px] object-cover transition-opacity duration-300 ease-in-out"
                        preload="auto"
                      >
                        <source
                          src={videoAula}
                          //type={`video/${getFormat(videoAula)}`}
                          className="w-full h-full"
                        />
                        Seu navegador não suporta a reprodução de vídeos.
                      </video>

                      :
                      <iframe
                        src={videoAula}
                        className="w-full h-[400px] object-cover transition-opacity duration-300 ease-in-out"
                        title="YouTube video player"
                        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                        frameBorder="0"
                        allowFullScreen
                      >
                        Seu navegador não suporta iframes
                      </iframe>
                    )
                    :
                    <p className='text-gray-500 text-xl text-center p-5 rounded-md my-3'>Nenhuma vídeo aula foi selecionada para esta aula até o momento!</p>
                  }
                  <div className='bg-black w-full p-2 text-center'>
                    {tipoVideoAula === 'cloud' &&
                      <>
                        <input id="videoAulaInput" type="file" accept=".mp4,.mkv" onChange={uploadAula} className="hidden disabled:opacity-50" disabled={processando} />
                        <div className="text-white text-xl cursor-pointer hover:text-orange-600" onClick={() => document.getElementById('videoAulaInput').click()}>Trocar Vídeo</div>
                      </>
                    }
                  </div>
                </div>

                <div className='flex flex-col w-full sm:w-1/2'>
                  <input type="text" value={tituloAula} onChange={e => setTituloAula(e.target.value)} className="input-master page-title-input disabled:opacity-50" disabled={processando} placeholder="Título" />

                  <textarea
                    value={descricaoAula}
                    onChange={handleTextChange}
                    className="input-master page-title-input disabled:opacity-50" disabled={processando}
                    placeholder="Descrição"
                    rows={editRows}
                  />

                  <div className='my-4 text-white'>
                    <label className='mr-4'>
                      <input
                        type="radio"
                        value="cloud"
                        checked={tipoVideoAula === 'cloud'}
                        onChange={e => setTipoVideoAula(e.target.value)}
                        className="mr-2 text-orange-500 focus:ring-orange-500 disabled:opacity-50" disabled={processando}
                      />
                      Cloud
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="youtube"
                        checked={tipoVideoAula === 'youtube'}
                        onChange={e => setTipoVideoAula(e.target.value)}
                        className="mr-2 text-orange-500 focus:ring-orange-500 disabled:opacity-50" disabled={processando}
                      />
                      YouTube
                    </label>
                  </div>

                  <input
                    type="text"
                    value={videoAula}
                    onChange={e => setVideoAula(e.target.value)}
                    className="input-master page-title-input disabled:opacity-50" disabled={processando}
                    placeholder={tipoVideoAula === 'cloud' ? "ID da Vídeo Aula" : "URL do YouTube"}
                  />

                  <button className='salvarUpdate disabled:opacity-50' disabled={processando} onClick={() => atualizarAula(null)}>Salvar Alterações</button>
                  <p className='text-green-500 my-5 text-center transition-all'>{alteracoesSalvas}</p>
                  <p className='text-red-500 my-5 text-center transition-all'>{erro}</p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MasterAulaAdmin;