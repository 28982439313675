import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';

import { Helmet } from 'react-helmet'
import axios from 'axios'
import { toast } from 'react-toastify';


import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import PopupAgendamento from '../components/popups/add_consultoria'
import './meu-perfil.css'
import { Link, useHistory, useLocation } from 'react-router-dom'


const MeuPerfil = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [mostrarDadosPessoais, setMostrarDadosPessoais] = useState(true);
  const [mostrarAlterarSenha, setMostrarAlterarSenha] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [nome, setNome] = useState(usuarioAtual.nome);
  const [email, setEmail] = useState(usuarioAtual.email);
  const [fotoPerfil, setFotoPerfil] = useState(usuarioAtual.foto_perfil);
  const [senha, setSenha] = useState(null)
  const [confirmarSenha, setConfirmarSenha] = useState(null);
  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  const mostrarContainerDadosPessoais = () => {
    setMostrarDadosPessoais(true);
    setMostrarAlterarSenha(false);
  };

  const mostrarContainerAlterarSenha = () => {
    setMostrarDadosPessoais(false);
    setMostrarAlterarSenha(true);
  };

  const handleNomeChange = event => {
    setNome(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const uploadFile = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/upload/profile/${usuarioAtual._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        setFotoPerfil(response.data.usuario.foto_perfil);
        toast.success('Foto de perfil atualizada com sucesso!');
      } catch (err) {
        console.error(err.response || err.message);
        toast.error('Erro ao enviar a foto de perfil.');
      }
    } else {
      toast.error('Nenhum arquivo selecionado.');
    }
  };




  const handleSubmit = async () => {

    if (mostrarDadosPessoais) {
      if (!nome || !email || !fotoPerfil) {
        toast.error('Por favor, preencha todos os campos obrigatórios.');
        return;
      }

      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/editar-usuario', {
          nome,
          email,
          foto_perfil: fotoPerfil,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        const usuario = {
          ...usuarioAtual,
          nome,
          email,
          foto_perfil: fotoPerfil
        }

        dispatch(setUsuario(usuario));
        toast.success('Alterações salvas');
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error('Não foi possível salvar seus dados.')
        }
      }


    } else {

      if (!senha || !confirmarSenha) {
        toast.error("Preencha os campos para mudar sua senha");
        return
      }

      if (senha !== confirmarSenha) {
        toast.error("As duas senhas não coincidem")
        return;
      }

      try {

        const alterarSenha = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/alterar-senha', {
          novaSenha: senha
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (alterarSenha.data && alterarSenha.data.message) {
          toast.success(alterarSenha.data.message)
        }

      } catch (err) {
        toast.error("Não foi possível alterar sua senha")
      }

    }

  };


  function formatarData(data) {
    const meses = [
      "Janeiro", "Fevereiro", "Março",
      "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro",
      "Outubro", "Novembro", "Dezembro"
    ];

    const dataObj = new Date(data);
    const dia = dataObj.getDate();
    const mes = meses[dataObj.getMonth()];
    const ano = dataObj.getFullYear();

    return `${dia} de ${mes} de ${ano}`;
  }


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.replace('/login');
  };

  return (
    <div className='w-full'>
      <Helmet>
        <title>Simbium</title>
        <meta property="og:title" content="Simbium" />
      </Helmet>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className='flex flex-col space-y-10'>
        <div className='lg:p-5 flex flex-row gap-8 lg:mt-14 pt-5 lg:pt-10 space-x-5'>
          {showMenu &&
            <div className='w-3/12 mb-5'>
              <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>
          }
          <div className={`${showMenu ? 'w-9/12' : 'w-[98vw] sm:w-11/12 mx-1 sm:mx-0'} mb-5 mx-auto`}>
            <div className="flex flex-col gap-8 w-full p-7  rounded-lg">
              <div className="">
                <div className="meu-perfil-opcoes-perfil">
                  <span className={`${mostrarDadosPessoais ? 'meu-perfil-text01' : 'meu-perfil-text02'}`} onClick={mostrarContainerDadosPessoais}>Dados Pessoais</span>
                  <span className={`${mostrarAlterarSenha ? 'meu-perfil-text01' : 'meu-perfil-text02'}`} onClick={mostrarContainerAlterarSenha}>Alterar senha</span>
                </div>
                <div className="meu-perfil-container04">
                  <div className="meu-perfil-contedo">
                    {mostrarDadosPessoais && (
                      <div id="container-dados-pessoais" className="meu-perfil-dados-pessoais">
                        <div className="meu-perfil-container05">
                          <img
                            alt="image"
                            src={fotoPerfil ? fotoPerfil : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                            className="meu-perfil-image"
                          />
                          <span className="meu-perfil-text03" htmlFor="fileInput" onClick={() => {
                            document.querySelector("#fileInput").click()
                          }}>
                            Alterar foto</span>
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={uploadFile}
                          />
                        </div>
                        <div className="meu-perfil-inputs">
                          <div className="meu-perfil-container06">
                            <span className="meu-perfil-text04">
                              <span>Nome</span>
                            </span>
                            <div className="meu-perfil-container07">
                              <svg
                                viewBox="0 0 731.4285714285713 1024"
                                className="meu-perfil-icon02"
                              >
                                <path d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"></path>
                              </svg>
                              <input
                                type="text"
                                value={nome}
                                placeholder="Insira seu nome"
                                className="meu-perfil-textinput input"
                                onChange={handleNomeChange}
                              />
                            </div>
                          </div>
                          <div className="meu-perfil-container08">
                            <span className="meu-perfil-text07">E-mail</span>
                            <div className="meu-perfil-container09">
                              <svg
                                viewBox="0 0 1024 1024"
                                className="meu-perfil-icon04"
                              >
                                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                              </svg>
                              <input
                                type="text"
                                value={email}
                                placeholder="Insira seu e-mail"
                                className="meu-perfil-textinput1 input"
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                          <div className="meu-perfil-container10">
                            <span className="meu-perfil-text08">
                              <span>Acesso</span>
                            </span>
                            <div className="meu-perfil-container11">
                              <svg
                                viewBox="0 0 1024 1024"
                                className="meu-perfil-icon06"
                              >
                                <path d="M810 854v-470h-596v470h596zM810 170q34 0 60 26t26 60v598q0 34-26 59t-60 25h-596q-36 0-61-24t-25-60v-598q0-34 25-60t61-26h42v-84h86v84h340v-84h86v84h42zM726 470v84h-86v-84h86zM554 470v84h-84v-84h84zM384 470v84h-86v-84h86z"></path>
                              </svg>
                              <input
                                type="text"
                                value={formatarData(usuarioAtual.acesso)}
                                disabled
                                placeholder="Acesso"
                                className="meu-perfil-textinput2 input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {mostrarAlterarSenha && (
                      <div id="container-alterar-senha" className="meu-perfil-alterar-senha">
                        <div className="meu-perfil-container12">
                          <span className="meu-perfil-text11">
                            Defina a nova senha
                          </span>
                          <div className="meu-perfil-container13">
                            <svg
                              viewBox="0 0 658.2857142857142 1024"
                              className="meu-perfil-icon08"
                            >
                              <path d="M182.857 438.857h292.571v-109.714c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286v109.714zM658.286 493.714v329.143c0 30.286-24.571 54.857-54.857 54.857h-548.571c-30.286 0-54.857-24.571-54.857-54.857v-329.143c0-30.286 24.571-54.857 54.857-54.857h18.286v-109.714c0-140.571 115.429-256 256-256s256 115.429 256 256v109.714h18.286c30.286 0 54.857 24.571 54.857 54.857z"></path>
                            </svg>
                            <input
                              type="password"
                              placeholder="Insira a nova senha"
                              className="meu-perfil-textinput3 input"
                              onChange={(e) => { setSenha(e.target.value) }}
                            />
                          </div>
                        </div>
                        <div className="meu-perfil-container14">
                          <span className="meu-perfil-text12">Confirmação</span>
                          <div className="meu-perfil-container15">
                            <svg
                              viewBox="0 0 658.2857142857142 1024"
                              className="meu-perfil-icon10"
                            >
                              <path d="M182.857 438.857h292.571v-109.714c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286v109.714zM658.286 493.714v329.143c0 30.286-24.571 54.857-54.857 54.857h-548.571c-30.286 0-54.857-24.571-54.857-54.857v-329.143c0-30.286 24.571-54.857 54.857-54.857h18.286v-109.714c0-140.571 115.429-256 256-256s256 115.429 256 256v109.714h18.286c30.286 0 54.857 24.571 54.857 54.857z"></path>
                            </svg>
                            <input
                              type="password"
                              onChange={(e) => { setConfirmarSenha(e.target.value) }}
                              placeholder="Insira a nova senha novamente"
                              className="meu-perfil-textinput4 input"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSubmit()
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="meu-perfil-b-salvar" onClick={handleSubmit}>
                      <svg
                        viewBox="0 0 865.7188571428571 1024"
                        className="meu-perfil-icon12"
                      >
                        <path d="M207.429 877.714l52-52-134.286-134.286-52 52v61.143h73.143v73.143h61.143zM506.286 347.429c0-7.429-5.143-12.571-12.571-12.571-3.429 0-6.857 1.143-9.714 4l-309.714 309.714c-2.857 2.857-4 6.286-4 9.714 0 7.429 5.143 12.571 12.571 12.571 3.429 0 6.857-1.143 9.714-4l309.714-309.714c2.857-2.857 4-6.286 4-9.714zM475.429 237.714l237.714 237.714-475.429 475.429h-237.714v-237.714zM865.714 292.571c0 19.429-8 38.286-21.143 51.429l-94.857 94.857-237.714-237.714 94.857-94.286c13.143-13.714 32-21.714 51.429-21.714s38.286 8 52 21.714l134.286 133.714c13.143 13.714 21.143 32.571 21.143 52z"></path>
                      </svg>
                      <span className="meu-perfil-text13">{mostrarDadosPessoais ? 'Salvar Alterações' : 'Alterar Senha'}</span>
                    </div>
                    <button onClick={() => handleLogout()} className='text-red-500'>Sair da Conta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default MeuPerfil
