import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import AnimatedLoading from '../components/animated-loading';

import './indicacao.css'
import ModalCompra from '../components/modal-compra/main';
import { toast } from 'react-toastify';

const TrialExpirado = (props) => {

  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [periodo, setPeriodo] = useState(0)
  const [showModalPagamento, setShowModalPagamento] = useState(false)
  const [pacoteId, setPacoteId] = useState('');
  const [pacote, setPacote] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disableAll, setDisableAll] = useState(true);

  const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` };

  const [plano_aviao, setPlanoAviao] = useState({
    id: process.env.REACT_APP_PLANO_AVIAO,
    titulo: "Plano Avião 🛩️",
    preco: "197",
    preco_centavos: "00",
    periodo: "mensal",
    descricao: "",
    lista_itens: [
      "Acesso a aulas e treinamentos",
      "01 consultoria exclusiva",
    ],
  });

  const [plano_jato, setPlanoJato] = useState({
    id: process.env.REACT_APP_PLANO_JATO,
    titulo: "Plano Jato ✈️",
    preco: "697",
    preco_centavos: "00",
    periodo: "anual",
    descricao: "",
    lista_itens: ["Tudo do Plano Avião",
      "Acesso á todos os treinamentos",
      "01 consultoria exclusiva",
      //"Dashboard BI",
      //"Adicione até 03 colaboradores sem custos",
      "Acesso a compra de Automações",
      "Atualizações Futuras"
    ],
  });

  const [plano_foguete, setPlanoFoguete] = useState({
    id: process.env.REACT_APP_PLANO_FOGUETE,
    titulo: "Plano Foguete 🚀",
    preco: "997",
    preco_centavos: "00",
    periodo: "anual",
    descricao: "",
    lista_itens: ["Tudo do Plano Jato",
      "01 consultoria exclusiva por mês",
      "Acesso a todas as automações prontas gratuitamente",
      "Mentorias exclusivas",
      "Acesso aos Constéudos de Especialistas",
      "Acesso ao Forncecedores Catalogados",
      "Todas as atualizações exclusivas futuras",
    ],
  });

  const [baseUrl, setBaseUrl] = useState('/assine-simbium')
  useEffect(() => {
    if (usuarioAtual.tipo && usuarioAtual.tipo !== 'admin' && usuarioAtual.ref.pago === true) {
      if (new Date(usuarioAtual.acesso) >= new Date()) {
        window.location.replace('/')
      }
    }

    if (props.match.params.plan_id?.length) {
      setPacoteId(props.match.params.plan_id);
      setBaseUrl('/assine-simbium/' + props.match.params.plan_id)
    } else {
      window.history.replaceState({}, 'Default', '/assine-simbium/main');
      setBaseUrl('/assine-simbium/main')
    }
  }, [props.match.params.plan_id, usuarioAtual]);

  useEffect(() => {
    let isMounted = true;

    const fetchPacotes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/get-pacote/${pacoteId}`, {
          headers: header_geral,
        });
        if (response.data?.dados && isMounted) {
          setPacote(response.data.dados);
        } else {
          console.error("Erro ao buscar pacotes: ", response);
        }
      } catch (error) {
        console.error("Erro ao buscar pacotes:", error);
      }
    };
    if (pacoteId.length) fetchPacotes();

    return () => {
      isMounted = false;
    };
  }, [pacoteId]);

  useEffect(() => {
    let isMounted = true;

    const fetchPlanos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/listar-planos`, {
          headers: header_geral,
        });
        if (response.data && isMounted) {
          console.log("DADOS: ", response.data)
          setPlanos(response.data.derivados.filter(plano => pacote.planos.includes(plano.id)));
          console.log(response.data)
          console.log("PLANOS: ", planos)
        }
      } catch (error) {
        console.error("Erro ao buscar planos:", error);
      }
    };

    if (pacote) fetchPlanos();

    return () => {
      isMounted = false;
    };
  }, [pacote]);

  useEffect(() => {
    if (planos.length > 0) {
      const formatPrice = (price) => {
        const priceInt = parseInt(price / 100).toString();
        const priceCents = (price % 100).toFixed(0).padStart(2, '0');
        return { priceInt, priceCents };
      };

      const [planoAviaoPrice, planoJatoPrice, planoFoguetePrice] = planos.map(plano => formatPrice(plano.items[0].pricing_scheme.price));

      setPlanoAviao(prevState => ({
        ...prevState,
        preco: planoAviaoPrice.priceInt,
        preco_centavos: planoAviaoPrice.priceCents,
      }));

      setPlanoJato(prevState => ({
        ...prevState,
        preco: planoJatoPrice.priceInt,
        preco_centavos: planoJatoPrice.priceCents,
      }));

      setPlanoFoguete(prevState => ({
        ...prevState,
        preco: planoFoguetePrice.priceInt,
        preco_centavos: planoFoguetePrice.priceCents,
      }));
    }
    setLoading(false);
  }, [planos]);

  const [planoSelecionado, setPlanoSelecionado] = useState(plano_foguete)

  const createPlan = async (planName) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/create-plan`, { planName }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      if (response.status === 200) {
        console.log('Plan creation response:', response.data.message);
      }
    } catch (error) {
      console.error('Plan creation failed with error:', error.message);
      // throw new Error('Failed to create plan. Please check your input.');
    }
  }

  const [plan, setPlan] = useState(false);

  const abrirModalCompra = (planoId) => {
    let planoSelecionado;

    switch (planoId) {
      case process.env.REACT_APP_PLANO_AVIAO:
        setBaseUrl(baseUrl + '/plano-aviao')
        window.history.replaceState({}, 'Plano Avião', baseUrl + '/plano-aviao/dados-pessoais');
        planoSelecionado = plano_aviao;
        break;
      case process.env.REACT_APP_PLANO_JATO:
        setBaseUrl(baseUrl + '/plano-jato')
        window.history.replaceState({}, 'Plano Jato', baseUrl + '/plano-jato/dados-pessoais');
        planoSelecionado = plano_jato;
        break;
      case process.env.REACT_APP_PLANO_FOGUETE:
        setBaseUrl(baseUrl + '/plano-foguete')
        window.history.replaceState({}, 'Plano Foguete', baseUrl + '/plano-foguete/dados-pessoais');
        planoSelecionado = plano_foguete;
        break;
      default:
        // Caso o planoId não corresponda a nenhum plano conhecido
        planoSelecionado = null; // Ou outra ação, como exibir uma mensagem de erro
    }

    setPlanoSelecionado(planoSelecionado);
    setShowModalPagamento(true);
  }


  const irParaCheckout = async (planId) => {
    setLoading(true);
    setPlan(planId);
    try {
      const planos = [
        {
          id: process.env.REACT_APP_PLANO_AVIAO,
          amount: 19700,
          description: "Plano Mensal",
          code: process.env.REACT_APP_PLANO_AVIAO,
          quantity: 1
        },
        {
          id: process.env.REACT_APP_PLANO_JATO,
          amount: 99700,
          description: "Plano Anual Básico",
          code: process.env.REACT_APP_PLANO_JATO,
          quantity: 1
        },
        {
          id: process.env.REACT_APP_PLANO_FOGUETE,
          amount: 197000,
          description: "Plano Anual Completo",
          code: process.env.REACT_APP_PLANO_FOGUETE,
          quantity: 1
        }
      ];

      const selectedPlan = planos.find(plan => plan.id === planId);

      if (!selectedPlan) {
        throw new Error("Plano desconhecido!");
      }

      const installments = Array.from({ length: 12 }, (_, index) => {
        let total;
        if (index === 0) {
          total = selectedPlan.amount;
        } else if (index >= 1 && index <= 9) {
          total = selectedPlan.amount * 1.05;
        } else {
          total = selectedPlan.amount * 1.1;
        }

        return {
          number: index + 1,
          total: Math.round(total)
        };
      });

      const userId = usuarioAtual?._id;

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/checkout`, { userId, plano: selectedPlan, installments }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      if (response?.status === 200) {
        console.log('Resposta de criação do plano:', response);
        window.location.replace(response.data?.url);
      } else {
        throw new Error('Falha ao abrir o formulário de pagamento. Tente novamente');
      }
    } catch (error) {
      console.error('Falha na criação do plano:', error.message);
      throw new Error('Falha ao abrir o formulário de pagamento. Tente novamente');
    } finally {
      setPlan(null)
      setLoading(false);
    }
  };

  const updateUrl = (page) => {
    switch (page) {
      case 0:
        window.history.replaceState({}, 'Default', `${baseUrl}/dados-pessoais`);
        break;
      case 1:
        window.history.replaceState({}, 'Default', `${baseUrl}/endereco`);
        break;
      case 2:
        window.history.replaceState({}, 'Default', `${baseUrl}/informacoes-pagamento`);
        break;
      case 3:
        window.history.replaceState({}, 'Default', `${baseUrl}/resumo`);
        break;
    }
  }

  const closeModal = () => {
    const planId = props?.match?.params?.plan_id || 'main';
    setBaseUrl(`/assine-simbium/${planId}`);
    window.history.replaceState({}, 'Default', `/assine-simbium/${planId}`);
    setShowModalPagamento(false);
  };

  return (
    <div>
      {showModalPagamento && <ModalCompra usuarioAtual={usuarioAtual} plano={planoSelecionado} closeModal={() => closeModal()} updateUrl={(page) => updateUrl(page)} />}
      <div className='w-full min-h-screen bg-neutral-950 flex justify-center items-center align-middle'>
        <div className='w-11/12 sm:w-9/12 md:w-7/12 lg:w-9/12 h-fit bg-neutral-800 p-5 md:p-10 rounded-lg flex flex-col gap-10 my-10'>
          <h1 className='text-white text-4xl text-center'>Assine a Simbium</h1>
          <div className='rounded-lg border-2 border-white flex flex-row divide-x-2 overflow-hidden text-center text-white text-sm w-fit m-auto'>
            <div className={`p-2 hover:bg-orange-600 hover:text-white active:bg-orange-500 cursor-pointer ${periodo === 0 ? 'bg-white text-orange-600' : ''}`} onClick={() => setPeriodo(0)}>Planos Anuais</div>
            <div className={`p-2 hover:bg-orange-600 hover:text-white active:bg-orange-500 cursor-pointer ${periodo === 1 ? 'bg-white text-orange-600' : ''}`} onClick={() => setPeriodo(1)}>Plano Mensal</div>
          </div>
          {periodo === 0 ?
            <div className='w-full flex flex-col lg:flex-row gap-5'>
              <div className='border-2 border-orange-600 rounded-lg text-orange-600 p-5 md:p-10 flex flex-col gap-10 w-full lg:w-1/2 hover:scale-105 transition-all'>
                <h2 className='text-2xl md:text-4xl text-center font-sans font-bold'>{plano_jato.titulo}</h2>
                <div className=' text-white text-5xl text-center'><span className='text-lg'>R$</span> {plano_jato.preco}<span className='text-sm'>,{plano_jato.preco_centavos}</span>
                  {plano_jato.periodo === "anual" ?
                    <p className='text-xs text-neutral-500'>Em até 12x no cartão</p>
                    :
                    <p className='text-xs text-neutral-500'>Valor cobrado mensalmente</p>
                  }
                </div>
                <ul className='w-full space-y-5 text-sm sm:text-md md:text-lg text-white'>
                  {plano_jato.lista_itens.map((item, index) => (
                    <li key={index}>🗹 {item}</li>
                  ))}
                </ul>
                <button onClick={() => abrirModalCompra(plano_jato.id) /*irParaCheckout(plano_jato.id)*/} className='bg-orange-500 disabled:bg-orange-950 hover:bg-white hover:text-orange-500 disabled:hover:text-white active:bg-neutral-200 text-white rounded-full p-5 text-md md:text-xl flex flex-row align-middle items-center justify-center text-center space-x-5' disabled={loading}>
                  <span>Embarcar no Jato!</span>
                  {loading && plan === 1 && <AnimatedLoading className="w-5 h-5" />}
                </button>
              </div>
              <div className='bg-orange-600 rounded-lg text-white p-5 md:p-10 flex flex-col gap-10 w-full lg:w-1/2 mt-10 lg:mt-0 relative hover:scale-105 transition-all'>
                <strong className='w-7/12 md:w-fit text-center text-xs md:text-md py-2 px-3 bg-white border-2 border-orange-600 text-orange-600 rounded-full absolute -top-5 left-1/2 transform -translate-x-1/2 '>Mais Comprado 🔥</strong>
                <h2 className='text-2xl md:text-4xl text-center font-sans font-bol'>{plano_foguete.titulo}</h2>
                <div className=' text-orange-600 bg-white p-3 rounded-full text-3xl md:text-5xl text-center'><span className='text-lg'>R$</span> {plano_foguete.preco}<span className='text-sm'>,{plano_foguete.preco_centavos}</span>
                  {plano_foguete.periodo === "anual" ?
                    <p className='text-xs text-neutral-500'>Em até 12x no cartão</p>
                    :
                    <p className='text-xs text-neutral-500'>Valor cobrado mensalmente</p>
                  }
                </div>
                <ul className='w-full space-y-5 text-sm sm:text-md md:text-lg text-white'>
                  {plano_foguete.lista_itens.map((item, index) => (
                    <li key={index}>🗹 {item}</li>
                  ))}
                </ul>
                <button onClick={() => abrirModalCompra(plano_foguete.id) /*irParaCheckout(plano_foguete.id)*/} className='bg-yellow-500 disabled:bg-yellow-950 hover:bg-white hover:text-orange-500  disabled:hover:text-white active:bg-neutral-200 text-white rounded-full p-5 text-sm md:text-xl flex flex-row align-middle items-center justify-center text-center space-x-5' disabled={loading}>
                  <span>Embarcar no Foguete! 🎉</span>
                  {loading && plan === 2 && <AnimatedLoading className="w-5 h-5" />}
                </button>
              </div>
            </div>
            :
            <div className='w-full flex flex-col md:flex-row gap-5'>
              <div className='border-2 border-orange-600 rounded-lg text-orange-600 p-5 md:p-10 m-auto flex flex-col gap-10 w-full lg:w-1/2 hover:scale-105 transition-all'>
                <h2 className='text-2xl md:text-4xl text-center font-sans font-bol'>{plano_aviao.titulo}</h2>
                <div className=' text-white text-5xl text-center'><span className='text-lg'>R$</span> {plano_aviao.preco}<span className='text-sm'>,{plano_aviao.preco_centavos}</span>
                  {plano_aviao.periodo === "anual" ?
                    <p className='text-xs text-neutral-500'>Em até 12x no cartão</p>
                    :
                    <p className='text-xs text-neutral-500'>Valor cobrado mensalmente</p>
                  }
                </div>
                <ul className='w-full space-y-5 text-sm  sm:text-md md:text-lg text-white'>
                  {plano_aviao.lista_itens.map((item, index) => (
                    <li key={index}>🗹 {item}</li>
                  ))}
                </ul>
                <button onClick={() => abrirModalCompra(plano_aviao.id)/*irParaCheckout(plano_aviao.id)*/} className='border-2 border-orange-500 disabled:bg-orange-950 hover:bg-white hover:text-orange-500 disabled:hover:bg-white active:bg-neutral-200 text-white rounded-full p-5 text-md md:text-xl flex flex-row align-middle items-center justify-center text-center space-x-5' disabled={loading}>
                  <span>Embarcar no Avião!</span>
                  {loading && plan === 0 && <AnimatedLoading className="w-5 h-5" />}
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default TrialExpirado
