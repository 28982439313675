import React, { useEffect, useState } from 'react'
// import styles from './shorts.module.css'

const PopupShorts = (props) => {
    const [videoStack, setVideoStack] = useState({
        prev: props.video[0],
        current: props.video[1],
        next: props.video[2]
    });

    useEffect(() => {
        if (!videoStack.current || !videoStack.current === '') {
            props.fecharPopup()
        }
    }, [videoStack.current])

    useEffect(() => {
        let newVideoStack = {
            current: '',
            prev: '',
            next: ''
        };

        if (props.video && props.video.length > 0) {
            newVideoStack.prev = props.video[0]
        }

        if (props.video && props.video.length > 1) {
            newVideoStack.current = props.video[1]
        }

        if (props.video && props.video.length > 2) {
            newVideoStack.next = props.video[2]
        }

        setVideoStack(newVideoStack);
        console.log("VIDEO: ", videoStack)
    }, [props.video]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.key === 'ArrowUp' || event.key === 'ArrowLeft') && videoStack.prev !== '') {
                props.handleClickShort(videoStack.prev);
            } else if ((event.key === 'ArrowDown' || event.key === 'ArrowRight') && videoStack.next !== '') {
                props.handleClickShort(videoStack.next);
            }
        };

        document.body.addEventListener('keydown', handleKeyPress);

        return () => {
            document.body.removeEventListener('keydown', handleKeyPress);
        };
    }, [videoStack, props]);

    return (
        <div className="flex flex-col items-center align-middle justify-center fixed top-0 left-0 w-screen h-screen m-auto z-40 bg-black bg-opacity-75">
            <div className="flex items-center align-middle relative w-screen h-screen max-w-900px bg-black rounded-md">
                {videoStack.prev && <button
                    disabled={videoStack.prev === ''}
                    className="absolute left-0 ml-5 z-30 text-white text-4xl rounded-md cursor-pointer p-2 hover:text-5xl"
                    onClick={() => {
                        props.handleClickShort(videoStack.prev)
                    }}
                >
                    ❮
                </button>}
                <div className="w-full h-screen">
                    <video
                        src={`${videoStack.current.video}`}
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                        controls
                        controlsList="nodownload"
                        autoPlay
                        loop
                    >
                        Seu navegador não suporta a tag de vídeo.
                    </video>
                </div>
                <div className="w-fit flex flex-row items-center align-middle space-x-2 text-white absolute top-3 right-3.5 cursor-pointer z-30 p-2 rounded-full bg-orange-500 hover:bg-orange-700 active:bg-orange-900" onClick={props.fecharPopup}>
                    <svg
                        viewBox="0 0 804.5714285714286 1024"
                        className=" w-5 h-5" fill="#fff"
                    >
                        <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                    </svg>
                    <span>Fechar</span>
                </div>
                {videoStack.next &&
                    <button
                        disabled={videoStack.next === ''}
                        className="absolute right-0 mr-5 z-30 text-white text-4xl rounded-md cursor-pointer p-2 hover:text-5xl"
                        onClick={() => {
                            props.handleClickShort(videoStack.next)
                        }}
                    >
                        ❯
                    </button>
                }
            </div>

            {/*
        <div className={`${styles['popup']}`}>
            <button
                disabled={videoStack.prev === ''}
                className={styles["prev-shorts"]} onClick={() => {
                    props.handleClickShort(videoStack.prev)
                }}>
                <svg className={styles["chevron-icon"]} width="24" height="24" viewBox="0 0 24 24" strokeWidth="3" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 15l6 -6l6 6"></path>
                </svg>
            </button>
            <div className={styles['popup-container']}>
                <div style={{ width: '100%' }} className={`${styles['video']} ${styles['current']}`} dangerouslySetInnerHTML={{ __html: videoStack.current.video }} />
                <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
                    <svg
                        viewBox="0 0 804.5714285714286 1024"
                        className={styles['icon']}
                    >
                        <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
                    </svg>
                </div>
            </div>
            <button
                disabled={videoStack.next === ''}
                className={styles["next-shorts"]}
                onClick={() => {
                    props.handleClickShort(videoStack.next)
                }}>
                <svg className={styles["chevron-icon"]} width="24" height="24" viewBox="0 0 24 24" strokeWidth="3" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 9l6 6l6 -6"></path>
                </svg>
            </button>
        </div>*/}
        </div>
    )
}

export default PopupShorts
