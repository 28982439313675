import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom'; // Substituir useHistory por useHistory
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const AutomacoesHome = (props) => {

    const navigate = useHistory();  // Atualizar para useHistory
    const [secoesPaginas, setSecoesPaginas] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                const header_geral = { Authorization: `Bearer ${token}` };
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao-pagina-com-paginas-home`, { headers: header_geral });
                if (isMounted && response.status === 200) {
                    setSecoesPaginas(response.data.secoesComPaginas);
                } else {
                    console.error("Erro ao obter dados: ", response);
                }
            } catch (error) {
                console.error("Erro na requisição: ", error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);


    const entrarNaPagina = (pagina) => {
        navigate(`/master/${pagina.codigo}/`);
    };

    return (
        <section className='flex flex-col space-y-10 mt-10'>
            {secoesPaginas?.length > 0 ? (
                secoesPaginas.map((secaoPagina, secaoIndex) => (
                    <section key={secaoIndex} className='w-11/12 mx-auto'>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-md md:text-xl text-neutral-50 font-bold'>{secaoPagina.titulo}</h2>
                            {/*<a href='/master/chave-mestra' className='text-neutral-500 text-sm hover:text-orange-500 active:text-orange-800'>Ver Mais</a>*/}
                        </div>
                        <div className="w-full">
                            <div className="w-full overflow-x-auto scroll-smooth">
                                <div className="flex flex-row w-fit py-5 lg:px-0 gap-3 snap-x snap-mandatory whitespace-nowrap">
                                    {secaoPagina.paginas.map((pagina, index) => (
                                        <a
                                            href={`/master/${pagina.codigo}`}
                                            key={index}
                                            className="text-red-500 min-w-[45vw] sm:min-w-[35vw] md:min-w-[15vw] min-h-[75vw] sm:min-h-[65vw] md:min-h-[25vw] aspect-auto active:opacity-50 rounded-lg bg-neutral-500 bg-center bg-cover bg-no-repeat cursor-pointer border-2 border-neutral-900 transition-all hover:border-orange-500 snap-start"
                                            style={{
                                                backgroundImage: `url(${pagina.capaImagem ? pagina.capaImagem : '/malha.jpeg'}?timestamp=${new Date().getTime()})`
                                            }}>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>


                    </section>
                ))
            ) : (
                <p className='w-11/12 mx-auto text-white italic text-xs'>Nenhuma seção disponível no momento.</p>
            )}
        </section>
    );


};

export default AutomacoesHome;
